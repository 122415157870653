// @flow
import type {
  HandicappingStoreOpenModalAction,
  HandicappingStoreCloseModalAction,
  SetHandicappingStoreModalUrlAction
} from "@tvg/shared-actions/HandicappingStore";
import type { DepositType, PasswordRecoveryType } from "../types";

export type DepositPayloadType = {
  title: string,
  type: DepositType
};

export type PasswordRecoveryPayloadType = {
  title: string,
  type: PasswordRecoveryType
};

export type LoginOpenModalAction = {
  type: "OPEN_LOGIN_MODAL_V1",
  payload: React$Element<*>
};

export type termsContentType =
  | "tutorials"
  | "privacy policy"
  | "terms & conditions"
  | "wager responsibly"
  | "accessibility"
  | "";

export type modalMarkupContent = "tutorials";

export type LoginCloseModalAction = { type: "CLOSE_LOGIN_MODAL_V1" };
export type DepositsOpenModalAction = {
  type: "OPEN_DEPOSITS_MODAL",
  payload: DepositPayloadType
};
export type DepositsCloseModalAction = { type: "CLOSE_DEPOSITS_MODAL" };
export type PrfOpenModalAction = {
  type: "OPEN_PASSWORD_RECOVERY_MODAL",
  payload: PasswordRecoveryPayloadType
};
export type PrfCloseModalAction = { type: "CLOSE_PASSWORD_RECOVERY_MODAL" };
export type PromosOpenModalAction = { type: "OPEN_PROMOS_MODAL" };
export type PromosCloseModalAction = { type: "CLOSE_PROMOS_MODAL" };
export type EmailReferralOpenModalAction = {
  type: "OPEN_EMAIL_REFERRAL_MODAL"
};
export type EmailReferralCloseModalAction = {
  type: "CLOSE_EMAIL_REFERRAL_MODAL"
};
export type NavigationOpenModalAction = {
  type: "OPEN_NAVIGATION_MODAL",
  payload: string
};
export type NavigationCloseModalAction = {
  type: "CLOSE_NAVIGATION_MODAL",
  payload: string
};
export type PendingWithdrawalsCloseModalAction = {
  type: "CLOSE_PENDING_WITHDRAWALS_MODAL"
};

export type BetPreferencesCloseModalAction = {
  type: "CLOSE_BET_PREFERENCES_MODAL"
};

export type BetPreferencesOpenModalAction = {
  type: "OPEN_BET_PREFERENCES_MODAL"
};
export type PendingWithdrawalsOpenModalAction = {
  type: "OPEN_PENDING_WITHDRAWALS_MODAL"
};
export type WithdrawalsOpenModalAction = { type: "OPEN_WITHDRAW_MODAL" };
export type WithdrawalsCloseModalAction = { type: "CLOSE_WITHDRAW_MODAL" };
export type FeatureTogglesOpenModalAction = {
  type: "OPEN_FEATURE_TOGGLES_MODAL"
};
export type FeatureTogglesCloseModalAction = {
  type: "CLOSE_FEATURE_TOGGLES_MODAL"
};
export type FixDepositModalForSightlinePromptType = {
  type: "FIX_DEPOSIT_MODAL_SIGHTLINE_PROMPT",
  payload: {
    isSightlineRedirectModalOpen: boolean
  }
};
export type QuickDepositFullDepositRedirect = {
  type: "QUICK_DEPOSIT_FULL_DEPOSIT_REDIRECT"
};

export type SupportOpenModalAction = { type: "OPEN_SUPPORT_MODAL" };
export type SupportCloseModalAction = { type: "CLOSE_SUPPORT_MODAL" };

export type ContentCardsOpenModalAction = { type: "OPEN_CONTENT_CARDS_MODAL" };
export type ContentCardsCloseModalAction = {
  type: "CLOSE_CONTENT_CARDS_MODAL"
};

export type Actions =
  | DepositsOpenModalAction
  | DepositsCloseModalAction
  | PrfOpenModalAction
  | PrfCloseModalAction
  | PromosOpenModalAction
  | PromosCloseModalAction
  | EmailReferralOpenModalAction
  | EmailReferralCloseModalAction
  | NavigationOpenModalAction
  | NavigationCloseModalAction
  | PendingWithdrawalsCloseModalAction
  | PendingWithdrawalsOpenModalAction
  | BetPreferencesCloseModalAction
  | BetPreferencesOpenModalAction
  | WithdrawalsOpenModalAction
  | WithdrawalsCloseModalAction
  | FixDepositModalForSightlinePromptType
  | QuickDepositFullDepositRedirect
  | FeatureTogglesOpenModalAction
  | FeatureTogglesCloseModalAction
  | HandicappingStoreOpenModalAction
  | HandicappingStoreCloseModalAction
  | SupportOpenModalAction
  | SupportCloseModalAction
  | ContentCardsOpenModalAction
  | ContentCardsCloseModalAction
  | SetHandicappingStoreModalUrlAction
  | LoginCloseModalAction
  | LoginOpenModalAction;

export const openLoginModal = (
  loginComponent: React$Element<*>
): LoginOpenModalAction => ({
  type: "OPEN_LOGIN_MODAL_V1",
  payload: loginComponent
});

export const closeLoginModal = (): LoginCloseModalAction => ({
  type: "CLOSE_LOGIN_MODAL_V1"
});

export const openDepositsModal = (
  payload: DepositPayloadType
): DepositsOpenModalAction => ({
  type: "OPEN_DEPOSITS_MODAL",
  payload
});

export const closeDepositsModal = (): DepositsCloseModalAction => ({
  type: "CLOSE_DEPOSITS_MODAL"
});

export const openPasswordRecoveryModal = (
  payload: PasswordRecoveryPayloadType
): PrfOpenModalAction => ({
  type: "OPEN_PASSWORD_RECOVERY_MODAL",
  payload
});

export const closePasswordRecoveryModal = (): PrfCloseModalAction => ({
  type: "CLOSE_PASSWORD_RECOVERY_MODAL"
});

export const openPromosModal = (): PromosOpenModalAction => ({
  type: "OPEN_PROMOS_MODAL"
});

export const closePromosModal = (): PromosCloseModalAction => ({
  type: "CLOSE_PROMOS_MODAL"
});

export const openEmailReferralModal = (): EmailReferralOpenModalAction => ({
  type: "OPEN_EMAIL_REFERRAL_MODAL"
});

export const closeEmailReferralModal = (): EmailReferralCloseModalAction => ({
  type: "CLOSE_EMAIL_REFERRAL_MODAL"
});

export const openNavigationModal = (
  contentType: termsContentType
): NavigationOpenModalAction => ({
  type: "OPEN_NAVIGATION_MODAL",
  payload: contentType
});

export const closeNavigationModal = (): NavigationCloseModalAction => ({
  type: "CLOSE_NAVIGATION_MODAL",
  payload: ""
});

export const closePendingWithdrawalsModal =
  (): PendingWithdrawalsCloseModalAction => ({
    type: "CLOSE_PENDING_WITHDRAWALS_MODAL"
  });

export const openPendingWithdrawalsModal =
  (): PendingWithdrawalsOpenModalAction => ({
    type: "OPEN_PENDING_WITHDRAWALS_MODAL"
  });

export const closeBetPrefsModal = (): BetPreferencesCloseModalAction => ({
  type: "CLOSE_BET_PREFERENCES_MODAL"
});

export const openBetPrefsModal = (): BetPreferencesOpenModalAction => ({
  type: "OPEN_BET_PREFERENCES_MODAL"
});

export const fixDepositModalForSightlinePrompt = (
  isSightlineRedirectModalOpen: boolean
): FixDepositModalForSightlinePromptType => ({
  type: "FIX_DEPOSIT_MODAL_SIGHTLINE_PROMPT",
  payload: { isSightlineRedirectModalOpen }
});

export const quickDepositToFullDepositRedirect = () => ({
  type: "QUICK_DEPOSIT_FULL_DEPOSIT_REDIRECT"
});

export const openFeatureTogglesModal = (): FeatureTogglesOpenModalAction => ({
  type: "OPEN_FEATURE_TOGGLES_MODAL"
});

export const closeFeatureTogglesModal = (): FeatureTogglesCloseModalAction => ({
  type: "CLOSE_FEATURE_TOGGLES_MODAL"
});

export const openSupportModal = (): SupportOpenModalAction => ({
  type: "OPEN_SUPPORT_MODAL"
});
export const closeSupportModal = (): SupportCloseModalAction => ({
  type: "CLOSE_SUPPORT_MODAL"
});

export const openContentCardsModal = (): ContentCardsOpenModalAction => ({
  type: "OPEN_CONTENT_CARDS_MODAL"
});
export const closeContentCardsModal = (): ContentCardsCloseModalAction => ({
  type: "CLOSE_CONTENT_CARDS_MODAL"
});
