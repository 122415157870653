// @flow

import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { buildText } from "../../_atom/Text";
import { grow } from "../../_static/Transitions";

import buildColor from "../../_static/ColorPalette";

export const LinkFooter = styled(Link).attrs((props) => ({
  "data-qa-label": `footerLink-${props.label}`
}))`
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;
  color: ${(props) =>
    props.selected ? buildColor("grey", "000") : buildColor("blue", "300")};
  ${(props) =>
    props.istextaside
      ? css`
          flex-direction: row;
          justify-content: center;
          padding: 8px;

          & > svg {
            margin-right: 8px;
          }
        `
      : css`
          padding: 8px 8px 4px;
          flex-direction: column;
          justify-content: space-between;
        `};

  &:active {
    color: ${buildColor("grey", "000")};

    & > svg {
      fill: ${buildColor("white", "100")};
    }
  }

  &::after {
    ${(props) =>
      props.counter > 0 &&
      css`
        content: "${props.counter}";
        position: absolute;
        top: 5px;
        right: calc(50% - 15px);
        border-radius: 20px;
        height: 16px;
        line-height: 16px;
        padding: 0 5px;
        text-align: center;
        font-weight: bold;
        font-size: 11px;
        color: ${buildColor("white", "100")};
        background-color: ${buildColor("red", "500")};
        animation: none;
        animation-direction: normal;
      `};
  }

  &.run-animation::after {
    animation: ${grow} 0.25s;
  }

  & > span {
    font-weight: ${(props) => props.selected && "bold"};
    font-size: ${(props) => props.fontSize}px;
  }
`;

export const Text = styled(
  buildText({ tag: "span", qaLabel: "footerLink-text" })
)`
  color: ${(props) => props.color};
`;
