// @flow
import type { Actions } from "./actions";

export type State = {
  favoriteTracks: [],
  wagerProfile: string,
  accountNumber: string,
  shouldUpdate: boolean,
  openTracks: string[]
};

export const initialState = {
  favoriteTracks: [],
  wagerProfile: "PORT-Generic",
  accountNumber: "",
  shouldUpdate: false,
  openTracks: []
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "TRACKSAZ_TOGGLE_TRACK": {
      return {
        ...state,
        openTracks: action.payload.openTracks
      };
    }
    default: {
      return state;
    }
  }
}
