// @flow
import type { Dispatch } from "redux";
import type { RouterHistory } from "react-router-dom";
import { find, get } from "lodash";
import mediator from "@tvg/mediator";
import type { NullaryFn } from "@tvg/types/Functional";
import { openMyBets } from "@tvg/my-bets/src/actions";
import { openHandicappingStoreModal } from "@tvg/shared-actions/HandicappingStore";
import { openMyBetsStandalone } from "@tvg/sh-lib-my-bets/redux/actions";
import { toggleQuickDepositModal } from "@tvg/sh-lib-paws/redux/slices/quickDepositModalSlice";
import { LOGIN_ACTIVE_FLOWS } from "@tvg/login-controller/src/reducers/modalReducer";
import { toggleQuickWithdraw } from "@tvg/sh-lib-paws/redux/slices/quickWithdrawSlice";

import {
  openPromosModal,
  openBetPrefsModal,
  openDepositsModal,
  openPasswordRecoveryModal,
  openNavigationModal,
  openPendingWithdrawalsModal,
  openFeatureTogglesModal,
  openSupportModal,
  openContentCardsModal,
  openEmailReferralModal
} from "../actions/modals";

export type Hash = {
  hash: string,
  call: NullaryFn<mixed>
};

export const Hashes = [
  {
    key: "bets",
    name: "Bets",
    hash: "#my-bets"
  },
  {
    key: "bet-cancel",
    name: "Bet Cancel",
    hash: "#bet-cancel"
  },
  {
    key: "betPrefs",
    name: "BetPrefs",
    hash: "#bet-prefs"
  },
  {
    key: "promos",
    name: "Promotions",
    hash: "#promos"
  },
  {
    key: "quick-deposit",
    name: "Deposit",
    hash: "#deposit"
  },
  {
    key: "deposit",
    name: "Deposit",
    hash: "#funds"
  },
  {
    key: "withdraw",
    name: "Widthdraw",
    hash: "#withdraw"
  },
  {
    key: "tutorials",
    name: "Tutorials",
    hash: "#tutorials"
  },
  {
    key: "privacyPolicy",
    name: "Privacy Policy",
    hash: "#privacyPolicy"
  },
  {
    key: "californiaPolicy",
    name: "California Privacy Policy",
    hash: "#state-specific-privacy-rights"
  },
  {
    key: "termsConditions",
    name: "Terms and Conditions",
    hash: "#termsConditions"
  },
  {
    key: "wagerResponsibly",
    name: "Wager Responsibly",
    hash: "#wagerResponsibly"
  },
  {
    key: "forgot-credentials",
    name: "Forgot Credentials",
    hash: "#forgot-credentials"
  },
  {
    key: "recover-email",
    name: "Recover email",
    hash: "#recover-email"
  },
  {
    key: "reset-credentials",
    name: "Credentials Recovery",
    hash: "#reset-credentials"
  },
  {
    key: "login",
    name: "Login",
    hash: "#login"
  },
  {
    key: "pendingWithdrawals",
    name: "Pending Withdrawals",
    hash: "#pending-withdrawals"
  },
  {
    key: "featureToggles",
    name: "Feature Toggles",
    hash: "#features"
  },
  {
    key: "handicapStore",
    name: "Handicapping Store",
    hash: "#handicap-store"
  },
  {
    key: "support",
    name: "Support",
    hash: "#support"
  },
  {
    key: "contentCards",
    name: "Content Cards",
    hash: "#content-cards"
  },
  {
    key: "accessibility",
    name: "Accessibility",
    hash: "#accessibility"
  },
  {
    key: "quickWithdraw",
    name: "Quick Withdraw",
    hash: "#quick-withdraw"
  },
  {
    key: "quickDeposit",
    name: "Quick Deposit",
    hash: "#quick-deposit"
  },
  {
    key: "emailReferral",
    name: "Email Referral",
    hash: "#invite"
  }
];

export const loginTriggerAction = {
  "my-bets": "footer_bet_tab_select",
  "handicap-store": "handicap_store",
  funds: "deposit",
  deposit: "quick-deposit",
  login: "login",
  withdraw: "withdraw",
  "pending-withdrawals": "pending_withdrawals",
  credentials: "credentials"
};

type ModalHashMapParams = {
  dispatch: Dispatch<*>,
  history: RouterHistory,
  location: string,
  isLogged: boolean,
  redirectedFromQuickToFull: boolean,
  previousHash?: string,
  myBetsStandaloneToggle: boolean,
  isLoginModalOpen: boolean,
  enablePawsQuickDeposits: boolean
};

export const modalHashMap = ({
  dispatch,
  history,
  location,
  isLogged,
  redirectedFromQuickToFull,
  previousHash,
  myBetsStandaloneToggle,
  isLoginModalOpen,
  enablePawsQuickDeposits
}: ModalHashMapParams): Hash[] => [
  {
    ...find(Hashes, (r) => r.key === "bets"),
    call: () =>
      !isLogged
        ? mediator.base.dispatch({
            type: "OPEN_LOGIN",
            payload: {
              triggerAction: loginTriggerAction["my-bets"]
            }
          })
        : dispatch(
            myBetsStandaloneToggle ? openMyBetsStandalone() : openMyBets()
          )
  },
  {
    ...find(Hashes, (r) => r.key === "handicapStore"),
    call: () =>
      !isLogged
        ? mediator.base.dispatch({
            type: "OPEN_LOGIN",
            payload: {
              triggerAction: loginTriggerAction["handicap-store"]
            }
          })
        : dispatch(openHandicappingStoreModal())
  },
  {
    ...find(Hashes, (r) => r.key === "promos"),
    call: () => dispatch(openPromosModal())
  },
  {
    ...find(Hashes, (r) => r.key === "betPrefs"),
    call: () => dispatch(openBetPrefsModal())
  },
  {
    ...find(Hashes, (r) => r.key === "quick-deposit"),
    call: () => {
      if (!isLogged) {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            triggerAction: loginTriggerAction.deposit
          }
        });
      } else if (enablePawsQuickDeposits) {
        history.push(`${location}#quick-deposit`);
      } else {
        dispatch(
          openDepositsModal({
            title: redirectedFromQuickToFull ? "Deposit" : "Quick deposit",
            type: redirectedFromQuickToFull ? "deposit" : "quick-deposit"
          })
        );
      }
    }
  },
  {
    ...find(Hashes, (r) => r.key === "deposit"),
    call: () =>
      !isLogged
        ? mediator.base.dispatch({
            type: "OPEN_LOGIN",
            payload: {
              triggerAction: loginTriggerAction.funds
            }
          })
        : dispatch(
            openDepositsModal({
              title: "Deposit",
              type: "deposit"
            })
          )
  },
  {
    ...find(Hashes, (r) => r.key === "withdraw"),
    call: () =>
      !isLogged
        ? mediator.base.dispatch({
            type: "OPEN_LOGIN",
            payload: {
              triggerAction: loginTriggerAction.withdraw
            }
          })
        : dispatch(
            openDepositsModal({
              title: "Withdraw",
              type: "withdraw"
            })
          )
  },
  {
    ...find(Hashes, (r) => r.key === "featureToggles"),
    call: () => dispatch(openFeatureTogglesModal())
  },
  {
    ...find(Hashes, (r) => r.key === "forgot-credentials"),
    call: () => {
      const route = find(Hashes, (r) => r.key === "forgot-credentials");
      if (isLogged) return history.push(location);

      if (!isLoginModalOpen || previousHash !== get(route, "hash")) {
        return mediator.base.dispatch({
          type: "OPEN_LOGIN_FLOW",
          payload: {
            loginActiveFlow: LOGIN_ACTIVE_FLOWS["forgot-credentials"]
          }
        });
      }
      return null;
    }
  },
  {
    ...find(Hashes, (r) => r.key === "recover-email"),
    call: () => {
      const route = find(Hashes, (r) => r.key === "recover-email");
      if (isLogged) return history.push(location);

      // only dispatches the action if the hash actually changes
      // allowing for mutations on the store without dispatching the action below
      return !isLoginModalOpen || previousHash !== get(route, "hash")
        ? mediator.base.dispatch({
            type: "OPEN_LOGIN_FLOW",
            payload: {
              loginActiveFlow: LOGIN_ACTIVE_FLOWS["recover-email"],
              loginActiveFlowStatus: null
            }
          })
        : null;
    }
  },
  {
    ...find(Hashes, (r) => r.key === "reset-credentials"),
    call: () =>
      !isLogged
        ? dispatch(
            openPasswordRecoveryModal({
              title: "Credentials Recovery",
              type: "resetcredentials"
            })
          )
        : history.push(location)
  },
  {
    ...find(Hashes, (r) => r.key === "login"),
    call: () =>
      !isLogged
        ? mediator.base.dispatch({
            type: "OPEN_LOGIN",
            payload: {
              triggerAction: loginTriggerAction.login
            }
          })
        : history.push(location)
  },
  {
    ...find(Hashes, (r) => r.key === "pendingWithdrawals"),
    call: () => {
      if (!isLogged) {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            triggerAction: loginTriggerAction["pending-withdrawals"]
          }
        });
      } else {
        dispatch(openPendingWithdrawalsModal());
      }
    }
  },
  {
    ...find(Hashes, (r) => r.key === "tutorials"),
    call: () => dispatch(openNavigationModal("tutorials"))
  },
  {
    ...find(Hashes, (r) => r.key === "privacyPolicy"),
    call: () => dispatch(openNavigationModal("privacy policy"))
  },
  {
    ...find(Hashes, (r) => r.key === "californiaPolicy"),
    call: () => dispatch(openNavigationModal("privacy policy"))
  },
  {
    ...find(Hashes, (r) => r.key === "termsConditions"),
    call: () => dispatch(openNavigationModal("terms & conditions"))
  },
  {
    ...find(Hashes, (r) => r.key === "wagerResponsibly"),
    call: () => dispatch(openNavigationModal("wager responsibly"))
  },
  {
    ...find(Hashes, (r) => r.key === "support"),
    call: () => dispatch(openSupportModal())
  },
  {
    ...find(Hashes, (r) => r.key === "contentCards"),
    call: () => dispatch(openContentCardsModal())
  },
  {
    ...find(Hashes, (r) => r.key === "accessibility"),
    call: () => dispatch(openNavigationModal("accessibility"))
  },
  {
    ...find(Hashes, (r) => r.key === "quickWithdraw"),
    call: () => {
      dispatch(toggleQuickWithdraw(true));
    }
  },
  {
    ...find(Hashes, (r) => r.key === "quickDeposit"),
    call: () => {
      if (!isLogged) {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            triggerAction: loginTriggerAction.deposit
          }
        });
      } else {
        dispatch(toggleQuickDepositModal(true));
      }
    }
  },
  {
    ...find(Hashes, (r) => r.key === "emailReferral"),
    call: () => dispatch(openEmailReferralModal())
  }
];
