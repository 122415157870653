// @flow
export type IOSInitResponseData = {
  type: string,
  message: string,
  token: string,
  url: string,
  touchIdAvailable: boolean,
  touchIdEnabled: boolean,
  accountId: number,
  state: string,
  launchTimestamp: number,
  loadTimestamp: number,
  error: string,
  meta: string
};

export type IOSTouchIdResponseData = {
  accountId: number,
  token: string
};

export type IOSTouchIdResponseError = {
  message: string,
  errorCode: number
};

export type IOSInitResponseAction = {
  type: "IOS_INIT_RESPONSE",
  payload: IOSInitResponseData
};

export type IOSTouchIDResponseAction = {
  type: "IOS_TOUCH_ID_RESPONSE",
  payload: IOSTouchIdResponseData
};

export type IOSTouchIDErrorAction = {
  type: "IOS_TOUCH_ID_ERROR",
  payload: IOSTouchIdResponseError,
  error: boolean
};

export type iOSNotificationsStateType = {
  type: "IOS_NOTIFICATIONS_STATE",
  payload: {
    isNotificationsEnabled: boolean
  }
};

export type iOSTouchIdToggleAction = {
  type: "IOS_TOUCH_ID_TOGGLE",
  payload: boolean
};

export type iOSTouchIdChangeAction = {
  type: "IOS_TOUCH_ID_CHANGE",
  payload: {
    isChanging: boolean
  }
};

export type IOSActions =
  | IOSInitResponseAction
  | IOSTouchIDResponseAction
  | IOSTouchIDErrorAction
  | iOSNotificationsStateType
  | iOSTouchIdToggleAction
  | iOSTouchIdChangeAction;

export const iOSInitResponseAction = (
  responseData: IOSInitResponseData
): IOSInitResponseAction => ({
  type: "IOS_INIT_RESPONSE",
  payload: responseData
});

export const iOSTouchIDResponseAction = (
  responseData: IOSTouchIdResponseData
): IOSTouchIDResponseAction => ({
  type: "IOS_TOUCH_ID_RESPONSE",
  payload: responseData
});

export function iOSTouchIDErrorAction(
  err: IOSTouchIdResponseError
): IOSTouchIDErrorAction {
  return { type: "IOS_TOUCH_ID_ERROR", payload: err, error: true };
}

export const iOSNotificationsStateAction = (
  isNotificationsEnabled: boolean
): iOSNotificationsStateType => ({
  type: "IOS_NOTIFICATIONS_STATE",
  payload: { isNotificationsEnabled }
});
