// @flow
import axios from "axios";
import type { Dispatch } from "redux";
import { get } from "lodash";
import TVGConf from "@tvg/conf";
import { successUserPromos, failUserPromos } from "../actions/user";
import type { UserActions } from "../actions/user";
import type { OptedInPromo, UserOptedInPromos } from "../types";

// TODO: this should be on the tvg-api
export const tvgHeaders = () => ({
  "content-type": "application/json",
  "x-tvg-context": TVGConf().context(),
  "x-requested-with": "XMLHttpRequest"
});

export const transformUserOptedInPromos = (
  promos: OptedInPromo[]
): UserOptedInPromos => {
  const optedInPromos = {};

  promos.forEach((promo) => {
    optedInPromos[promo.id] = promo.optedIn;
  });

  return optedInPromos;
};

export const clearOptedInPromos = (dispatch: Dispatch<UserActions>) =>
  dispatch(successUserPromos({}));

export default (dispatch: Dispatch<UserActions>, userId: string) => {
  const url = `${TVGConf().config().service.pro}/users/${userId}/promotions`;
  const requestOptions = {
    method: "get",
    url,
    headers: tvgHeaders(),
    withCredentials: true
  };
  return axios(requestOptions)
    .then((response) =>
      dispatch(
        successUserPromos(
          transformUserOptedInPromos(get(response, "data.promotions", []))
        )
      )
    )
    .catch((err) => dispatch(failUserPromos(err)));
};
