// @flow
import { combineReducers } from "redux";
import quickLinksReducer from "@tvg/quick-links/redux-manager";

import homeReducer, {
  type State as HomeState,
  initialState as initialHomeState
} from "@tvg/home/src/store/reducers";

import topRacesReducer, {
  type State as TopRacesState,
  initialState as initialTopRacesState
} from "@tvg/top-races/src/reducers";

import tracksBuilderReducer, {
  type State as TracksBuilderState,
  initialState as initialTracksBuilderState
} from "@tvg/tracks-builder/src/reducers";

import mhnReducer, {
  type State as MHNState,
  initialState as initialMHNState
} from "@tvg/mobile-home/src/reducers";

// race program reducers
import RaceProgram, {
  type State as PGMState,
  initialState as initialPGMState
} from "@tvg/program-page/reducers/app";

import RaceProgramVideo, {
  initialState as initialVideoPGMState
} from "@tvg/program-page/reducers/video";

import mobileAccountReducers, {
  type State as MobileAccountState,
  initialState as initialMobileAccount
} from "@tvg/mobile-account/src/reducers/mobileAccountReducers";

import type { Video as VideoPGMState } from "@tvg/types/Race";

import RaceProgramLoading, {
  type Loading as LoadingPGMState,
  initialState as initialLoadingPGMState
} from "@tvg/program-page/reducers/loading";

import RaceProgramPoolsNInfo, {
  type PoolsNInfo as poolsNInfoPGMState,
  initialState as initialPoolsNInfoPGMState
} from "@tvg/program-page/reducers/poolsNInfo";

import myBetsReducer, {
  type State as MyBetsState,
  initialState as initialMyBetsState
} from "@tvg/my-bets/src/reducers";

import myBetsStandaloneReducer, {
  type State as MyBetsStandaloneState,
  initialState as initialMyBetsStandaloneState
} from "@tvg/sh-lib-my-bets/redux/reducers";

import upNextRacesReducer, {
  type State as UpNextRacesState,
  initialState as initialUpNextRacesState
} from "@tvg/tracks/src/tracks-upnext/reducers";

import RaceFiltersReducer, {
  type State as RaceFiltersState,
  initialState as initialRaceFiltersState
} from "@tvg/tracks-header/reducers";

import tracksAzReducer, {
  type State as TracksAzState,
  initialState as initialTracksAzState
} from "@tvg/tracks/src/tracks-az/reducers";

import mtpStatusReducer, {
  type State as MtpStatusState,
  initialState as initialMtpStatusState
} from "@tvg/mtp-update/src/reducers";

import tracksAzFavoritesReducer, {
  type State as TracksAzFavoritesState,
  initialState as initialTracksAzFavoritesState
} from "@tvg/tracks/src/tracks-favorites/reducers";

import messengerReducer, {
  type State as MessengerState,
  initialState as initialMessengerState
} from "@tvg/messenger/src/reducers";

import locationSplashReducer, {
  type State as locationSplashState,
  initialState as initialLocationSplashState
} from "@tvg/location-splash/reducer";

import withdrawalsReducer, {
  type State as withdrawalsState,
  initialState as initialWithdrawalsState
} from "@tvg/withdrawals/src/reducers";

import betTicketReducer, {
  type State as betTicketState,
  initialState as initialBetTicketState
} from "@tvg/bet/reducers";

import wagerRewardsReducer, {
  type State as wagerRewardsState,
  initialState as initialWagerRewardsState
} from "@tvg/wager-rewards/src/reducers";

import onboardingsReducer, {
  type State as onboardingsState,
  initialState as initialOnboardingsState
} from "@tvg/onboardings/src/reducers";

import revalidateLocationReducer, {
  type State as RevalidateLocationState,
  initialState as initialRevalidateLocationState
} from "@tvg/revalidate-location/reducer";

import promosReducer, {
  type State as PromosState,
  initialState as initialPromosState
} from "@tvg/promos/src/reducers";

import referReducer, {
  type State as ReferState,
  initialState as initialReferState
} from "@tvg/refer/src/reducers";

import accountTransactionsReducer, {
  type State as AccountTransactionsState,
  initialState as initialAccountTransactionsState
} from "@tvg/account-transactions/src/reducers";

import registrationReducer, {
  type State as RegistrationState,
  initialState as initialRegistrationState
} from "@tvg/rma/src/reducer";

import preferencesReducer, {
  type State as PreferencesState,
  initialState as initialPreferencesState
} from "@tvg/sh-lib-preferences/redux/reducers";

import loginControllerReducers from "@tvg/login-controller/src/reducers";

import brazeReducer, {
  type State as BrazeState,
  initialState as initialBrazeReducer
} from "@tvg/braze/src/store/reducers";

import pawsReducer, {
  type PawsState,
  initialPawsState
} from "@tvg/sh-lib-paws/redux/reducers";

import seoRaceTracksReducer, {
  type State as seoRaceTracksState,
  initialState as initialSeoRaceTracksState
} from "@tvg/tracks/src/v2/redux/reducers";

import promosOnboardingReducer, {
  type PromoOnboardingState,
  initialState as initialPromosOnboardingState
} from "@tvg/sh-lib-promos-onboarding/redux/reducers";

import capiReducer, {
  type State as CapiState,
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  type State as UserState,
  initialState as initialUserState
} from "./App/userDataReducer";

import geoLocationReducer, {
  type State as GeoLocationState,
  initialState as initialGeoLocationState
} from "./App/geoLocationReducer";

import iOSEventsReducer, {
  type State as IOSEventsState,
  initialState as initialiOSEventsState
} from "./App/iOSEventsReducer";

import userFavoritesReducer, {
  type State as UserFavoritesState,
  initialState as initialUserFavoritesState
} from "./App/userFavoritesReducer";

import modalReducer, {
  type State as ModalState,
  initialState as initialModalState
} from "./App/modalReducer";

import alertsReducer, {
  type State as AlertsState,
  initialState as initialAlertsState
} from "./App/alertsReducer";

import appReducer, {
  type State as AppState,
  initialState as initialAppState
} from "./App/appReducer";

export type State = {
  app: AppState,
  capi: CapiState,
  userData: UserState,
  userFavorites: UserFavoritesState,
  raceFilters: RaceFiltersState,
  homePage: MHNState,
  RaceProgram: PGMState,
  RaceProgramVideo: VideoPGMState,
  RaceProgramLoading: LoadingPGMState,
  RaceProgramPoolsNInfo: poolsNInfoPGMState,
  geolocation: GeoLocationState,
  mtpStatus: MtpStatusState,
  myBets: MyBetsState,
  myBetsStandalone: MyBetsStandaloneState,
  upNextRaces: UpNextRacesState,
  tracksAz: TracksAzState,
  tracksAzFavorites: TracksAzFavoritesState,
  seoRaceTracks: seoRaceTracksState,
  messenger: MessengerState,
  ios: IOSEventsState,
  modal: ModalState,
  locationSplash: locationSplashState,
  mobileAccount: MobileAccountState,
  withdrawals: withdrawalsState,
  alerts: AlertsState,
  BetTicket: betTicketState,
  wagerRewards: wagerRewardsState,
  onboardings: onboardingsState,
  revalidateLocation: RevalidateLocationState,
  home: HomeState,
  topRaces: TopRacesState,
  topTracks: TracksBuilderState,
  promos: PromosState,
  refer: ReferState,
  registration: RegistrationState,
  accountTransactions: AccountTransactionsState,
  preferences: PreferencesState,
  paws: PawsState,
  promosOnboarding: PromoOnboardingState,
  brazeData: BrazeState
};

export const initialState = {
  app: initialAppState,
  homePage: initialMHNState,
  RaceProgram: initialPGMState,
  RaceProgramVideo: initialVideoPGMState,
  RaceProgramLoading: initialLoadingPGMState,
  RaceProgramPoolsNInfo: initialPoolsNInfoPGMState,
  capi: initialCapiState,
  userData: initialUserState,
  userFavorites: initialUserFavoritesState,
  raceFilters: initialRaceFiltersState,
  geolocation: initialGeoLocationState,
  mtpStatus: initialMtpStatusState,
  myBets: initialMyBetsState,
  myBetsStandalone: initialMyBetsStandaloneState,
  upNextRaces: initialUpNextRacesState,
  tracksAz: initialTracksAzState,
  tracksAzFavorites: initialTracksAzFavoritesState,
  messenger: initialMessengerState,
  ios: initialiOSEventsState,
  modal: initialModalState,
  locationSplash: initialLocationSplashState,
  withdrawals: initialWithdrawalsState,
  alerts: initialAlertsState,
  mobileAccount: initialMobileAccount,
  BetTicket: initialBetTicketState,
  wagerRewards: initialWagerRewardsState,
  onboardings: initialOnboardingsState,
  revalidateLocation: initialRevalidateLocationState,
  home: initialHomeState,
  topRaces: initialTopRacesState,
  topTracks: initialTracksBuilderState,
  promos: initialPromosState,
  refer: initialReferState,
  registration: initialRegistrationState,
  accountTransactions: initialAccountTransactionsState,
  preferences: initialPreferencesState,
  paws: initialPawsState,
  seoRaceTracks: initialSeoRaceTracksState,
  promosOnboarding: initialPromosOnboardingState,
  brazeData: initialBrazeReducer
};

export default combineReducers({
  app: appReducer,
  homePage: mhnReducer,
  RaceProgram,
  RaceProgramVideo,
  RaceProgramLoading,
  RaceProgramPoolsNInfo,
  capi: capiReducer,
  userData: userDataReducer,
  userFavorites: userFavoritesReducer,
  raceFilters: RaceFiltersReducer,
  geolocation: geoLocationReducer,
  myBets: myBetsReducer,
  myBetsStandalone: myBetsStandaloneReducer,
  upNextRaces: upNextRacesReducer,
  mtpStatus: mtpStatusReducer,
  tracksAz: tracksAzReducer,
  tracksAzFavorites: tracksAzFavoritesReducer,
  seoRaceTracks: seoRaceTracksReducer,
  messenger: messengerReducer,
  withdrawals: withdrawalsReducer,
  ios: iOSEventsReducer,
  modal: modalReducer,
  mobileAccount: mobileAccountReducers,
  locationSplash: locationSplashReducer,
  alerts: alertsReducer,
  BetTicket: betTicketReducer,
  wagerRewards: wagerRewardsReducer,
  onboardings: onboardingsReducer,
  revalidateLocation: revalidateLocationReducer,
  quickLinks: quickLinksReducer,
  home: homeReducer,
  topRaces: topRacesReducer,
  topTracks: tracksBuilderReducer,
  promos: promosReducer,
  refer: referReducer,
  registration: registrationReducer,
  accountTransactions: accountTransactionsReducer,
  preferences: preferencesReducer,
  paws: pawsReducer,
  promosOnboarding: promosOnboardingReducer,
  ...loginControllerReducers,
  brazeData: brazeReducer
});
