// @flow

import React from "react";
import mediator from "@tvg/mediator";
import DownloadButton from "@tvg/atomic-ui/_atom/DownloadButton";

const openDownloadModal = () => {
  mediator.base.dispatch({
    type: "OPEN_ACCOUNT_SUMMARY_DOWNLOAD_MODAL"
  });
};

const DownloadButtonComp = () => (
  <DownloadButton handleClick={openDownloadModal} />
);

DownloadButtonComp.defaultProps = {};

export default DownloadButtonComp;
