// @flow
// $FlowFixMe
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { get } from "lodash";

import Messenger, {
  type TemplateMessage
} from "@tvg/atomic-ui/_templates/Messenger";

import service from ".";
import { type Actions } from "./actions";

type Props = {
  dispatch: Dispatch<Actions>,
  messages: TemplateMessage[],
  isMyBetsModalOpen: boolean,
  isAnyModalOpen: boolean
};

export const MessengerComponent = ({
  dispatch,
  messages = [],
  isMyBetsModalOpen = false,
  isAnyModalOpen = false
}: Props) => {
  useEffect(() => {
    service.setDispatcher(dispatch);
  }, []);

  return (
    <Messenger
      messages={messages}
      isFooterVisible={!(isAnyModalOpen || isMyBetsModalOpen)}
    />
  );
};

export default connect(
  (store) => ({
    messages: store.messenger.messages,
    isAnyModalOpen: store.modal.isModalOpen,
    isMyBetsModalOpen: get(store, "myBets.isOpen", false)
  }),
  (dispatch: Dispatch<Actions>) => ({ dispatch })
)(MessengerComponent);
