import React from "react";
import formatSequencial from "@tvg/formatter/numeric";
import { get } from "lodash";
import { CustomBetSelectionsViewProps } from "../types";
import {
  AttentionContainer,
  Content,
  DetailAction,
  DetailsHr,
  LabelDot,
  LabelIconWrapper,
  LegParagraph,
  PickBetLabelIconContainer,
  PositionsContainer,
  RowWrapper,
  SaddleContainer,
  TextAndSelectionContainer,
  WillPaysInfoContainer,
  WillPaysLabel
} from "./styled-components";
import { BetSelections, SaddleSelectionsProps } from "../../../../../src";
import { Paragraph } from "../../../Typography";
import { Icon } from "../../../Icon";

export const CustomBetSelectionsView: React.FC<
  CustomBetSelectionsViewProps
> = ({
  completeSelection,
  numWagerableRunners,
  type,
  isKey,
  isBox,
  isWheel,
  isLeg,
  isExoticBet,
  currentRaceNumber,
  scratches,
  breed,
  wagerDetails,
  onDetailsView,
  racesStatus
}) => {
  const renderKeyOrKeyBox = (index: number) => {
    const isKeyBox = isKey && isBox;
    const defaultText = isKeyBox ? "Box" : "w/";

    return (
      <Paragraph qaLabel={`selection-label-key${isKeyBox ? "-box" : ""}`}>
        {index === 0 ? (
          <Icon
            name="key"
            size="s"
            qaLabel="key"
            lineColor="myBets.unknown.textColor"
          />
        ) : (
          defaultText
        )}
      </Paragraph>
    );
  };

  const renderKeyBoxOrKeyOrBox = (index: number) => {
    if (isKey) {
      return renderKeyOrKeyBox(index);
    }

    return <Paragraph qaLabel="selection-label-box">Box</Paragraph>;
  };

  const renderExoticOrLegBet = (index: number) => {
    const currentRaceStatus = get(racesStatus, index);

    return isExoticBet ? (
      <Paragraph qaLabel={`selection-label-${formatSequencial(index + 1)}`}>
        {formatSequencial(index + 1)}
      </Paragraph>
    ) : (
      <Content>
        <LegParagraph
          qaLabel={`selection-label-${formatSequencial(index + 1)}`}
          fontFamily="condensedRegular"
          ml="space-1"
          mr="space-3"
          raceStatus={currentRaceStatus}
        >
          {`LEG ${index + 1} - R${Number(currentRaceNumber) + index}`}
        </LegParagraph>
      </Content>
    );
  };

  const renderWheel = (index: number) => (
    <Paragraph qaLabel="selection-label-wheel">
      {index === 0 ? <></> : "w/"}
    </Paragraph>
  );

  const renderLegLabelIcon = (index: number) => {
    const currentRaceStatus = get(racesStatus, index);

    switch (currentRaceStatus) {
      case "attention":
        return (
          <PickBetLabelIconContainer>
            <LabelIconWrapper raceStatus="attention">
              <Icon
                qaLabel="attention-leg-icon"
                name="info"
                size="s"
                lineColor="myBets.attention.iconColor"
              />
            </LabelIconWrapper>
          </PickBetLabelIconContainer>
        );
      case "lost":
        return (
          <PickBetLabelIconContainer>
            <LabelIconWrapper raceStatus="lost">
              <Icon
                qaLabel="lost-leg-icon"
                name="error"
                size="s"
                lineColor="myBets.lost.iconColor"
              />
            </LabelIconWrapper>
          </PickBetLabelIconContainer>
        );
      case "win":
        return (
          <PickBetLabelIconContainer>
            <LabelIconWrapper raceStatus="win">
              <Icon
                qaLabel="win-leg-icon"
                name="success"
                size="s"
                lineColor="myBets.win.iconColor"
              />
            </LabelIconWrapper>
          </PickBetLabelIconContainer>
        );
      default:
        return (
          <PickBetLabelIconContainer>
            <LabelIconWrapper raceStatus={currentRaceStatus}>
              <LabelDot raceStatus={currentRaceStatus} />
            </LabelIconWrapper>
          </PickBetLabelIconContainer>
        );
    }
  };

  const renderLabelAndSelections = (
    selection: SaddleSelectionsProps[],
    index: number,
    hasWillPaysTag: boolean,
    showOdds: boolean
  ) => {
    const currentRaceStatus = get(racesStatus, index);

    return isLeg ? (
      <TextAndSelectionContainer
        hasDetails={get(wagerDetails, index, false)}
        isLeg={isLeg}
      >
        {(isKey || isBox) && renderKeyBoxOrKeyOrBox(index)}
        {(isExoticBet || isLeg) && renderExoticOrLegBet(index)}
        {isWheel && renderWheel(index)}
        {hasWillPaysTag && scratches?.[index].length !== selection.length && (
          <WillPaysInfoContainer>
            <LabelIconWrapper raceStatus="win">
              <Icon
                qaLabel="info"
                name="info"
                size="s"
                lineColor="content.info"
              />
            </LabelIconWrapper>
            Will Pays subject to change due to scratches
          </WillPaysInfoContainer>
        )}
        {!hasWillPaysTag &&
          currentRaceStatus === "attention" &&
          isLeg &&
          get(wagerDetails, index, false) && (
            <AttentionContainer>
              Awaiting official result to confirm the bet status.
            </AttentionContainer>
          )}

        <SaddleContainer
          isLeg={isLeg}
          hasDetails={get(wagerDetails, index, false)}
        >
          <BetSelections
            scratches={scratches?.[index]}
            numWagerableRunners={
              isLeg ? numWagerableRunners?.[index] : numWagerableRunners?.[0]
            }
            selections={selection}
            type={type}
            showBetType={false}
            breed={breed}
            wagerDetails={get(wagerDetails, index, false)}
            hideWillPaysTag={!hasWillPaysTag}
            showOdds={showOdds}
          />
          {get(wagerDetails, index, false) &&
            completeSelection &&
            completeSelection.length > 1 && <DetailsHr />}
        </SaddleContainer>
      </TextAndSelectionContainer>
    ) : (
      <>
        {(isKey || isBox) && renderKeyBoxOrKeyOrBox(index)}
        {(isExoticBet || isLeg) && renderExoticOrLegBet(index)}
        {isWheel && renderWheel(index)}
        <SaddleContainer
          isLeg={isLeg}
          hasDetails={get(wagerDetails, index, false)}
        >
          <BetSelections
            scratches={scratches?.[index]}
            numWagerableRunners={
              isLeg ? numWagerableRunners?.[index] : numWagerableRunners?.[0]
            }
            selections={selection}
            type={type}
            showBetType={false}
            breed={breed}
            wagerDetails={get(wagerDetails, index, false)}
            hideWillPaysTag={!hasWillPaysTag}
          />
          {get(wagerDetails, index, false) &&
            completeSelection &&
            completeSelection?.length > 1 && <DetailsHr />}
        </SaddleContainer>
      </>
    );
  };

  return completeSelection?.length ? (
    <>
      {completeSelection.map((selection, index) => {
        const willPaysExist = selection.some(
          (sel) => sel.willPay && sel.willPay > 0
        );
        const hasWillPaysTag =
          index === completeSelection.length - 1 &&
          willPaysExist &&
          scratches?.[index].length !== selection.length &&
          get(wagerDetails, index, false);

        const showOdds =
          willPaysExist &&
          get(wagerDetails, index, false) &&
          index !== completeSelection.length - 1;

        return (
          <PositionsContainer
            key={`${type}-${index.toString()}`}
            isLeg={isLeg}
            raceStatus={get(racesStatus, index)}
          >
            <RowWrapper isLeg={isLeg}>
              <DetailAction
                onClick={() => onDetailsView && onDetailsView(index)}
              />
              {isLeg && renderLegLabelIcon(index)}
              {renderLabelAndSelections(
                selection,
                index,
                hasWillPaysTag,
                showOdds
              )}
              {hasWillPaysTag && <WillPaysLabel>WILL PAYS</WillPaysLabel>}
              {isLeg && (
                <Icon
                  qaLabel={`${type}-${index}}-details-chevron`}
                  name={
                    get(wagerDetails, index, false)
                      ? "chevronUp"
                      : "chevronDown"
                  }
                  size="s"
                  lineColor="content.neutral"
                />
              )}
            </RowWrapper>
          </PositionsContainer>
        );
      })}
    </>
  ) : null;
};
