// @flow
import React from "react";
import { noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import type { Device } from "@tvg/types/Device";

import { LogoTVGBlack } from "../../_static/Logos";
import Button from "../../_atom/Buttons/button";

import {
  Container,
  Content,
  Logo,
  Title,
  Description
} from "./styled-components";

type Props = {
  device: Device,
  showLogo: boolean,
  title: string,
  description: string,
  showButton: boolean,
  buttonText?: string,
  buttonAction?: NullaryFn<void>,
  qaLabel: string
};

const SplashNotification = (props: Props) => (
  <Container device={props.device}>
    <Content device={props.device}>
      {props.showLogo && <Logo>{LogoTVGBlack(27, "")}</Logo>}

      <Title>{props.title}</Title>
      <Description dangerouslySetInnerHTML={{ __html: props.description }} />
      {props.showButton && (
        <Button
          isStretched
          type="primary"
          size="big"
          onClick={props.buttonAction}
          qaLabel={props.qaLabel}
        >
          {props.buttonText}
        </Button>
      )}
    </Content>
  </Container>
);

SplashNotification.defaultProps = {
  showLogo: true,
  showButton: true,
  buttonAction: noop,
  qaLabel: "splash-notification-button"
};

export default SplashNotification;
