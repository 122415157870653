// @flow
import type { TutorialListFromService } from "@tvg/types/OnBoarding";
import type { Actions } from "../actions";

export type State = {
  tutorialsList: TutorialListFromService[]
};

export const initialState = {
  tutorialsList: []
};

export default function capiReducer(
  state: State = initialState,
  action: Actions
) {
  switch (action.type) {
    case "SET_TUTORIALS_LIST": {
      return { ...state, tutorialsList: action.payload };
    }

    default: {
      return state;
    }
  }
}
