// @flow
import type { Message } from "@tvg/types/Messages";
import type { Actions } from "./actions";

export type State = {
  messages: Message[]
};

export const initialState = {
  messages: []
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "MESSENGER_ADD_MESSAGE": {
      return {
        ...state,
        messages: [...state.messages, action.payload.message]
      };
    }
    case "MESSENGER_REMOVE_MESSAGE": {
      return {
        ...state,
        // $FlowBug
        messages: [
          // $FlowFixMe
          ...state.messages.filter((m) => m.key !== action.payload.key)
        ]
      };
    }
    case "MESSENGER_REMOVE_ALL_MESSAGES": {
      return { ...state, messages: [] };
    }
    default: {
      return state;
    }
  }
}
