// @flow

import React from "react";
import ApolloContext from "@tvg/utils/apolloContext";
import BetCancel from "@tvg/bet-cancel";
import type { TransitionState } from "../../types";

export const BetCancelModal = ({
  transitionState
}: {
  transitionState: TransitionState
}) => (
  <ApolloContext.Consumer>
    {(value) => (
      <BetCancel
        transitionState={transitionState}
        behgClient={value.behgClient}
      />
    )}
  </ApolloContext.Consumer>
);

export default BetCancelModal;
