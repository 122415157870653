// @flow
import type { WagerProfile } from "@tvg/types/User";
import type { RaceInfoTracks } from "@tvg/types/Track";

import type { Actions } from "./actions";

export type GreyhoundsProfiles =
  | "PORT-FL"
  | "PORT-OR"
  | "PORT-WY"
  | "PORT-CO"
  | "PORT-CT"
  | "PORT-MA"
  | "PORT-AR"
  | "PORT-ZZ";

export type State = {
  ssrTracks: RaceInfoTracks[],
  profile: WagerProfile,
  favoritesTracks: string[],
  greyhoundsProfiles: GreyhoundsProfiles[],
  loading: boolean,
  shouldUpdate: boolean,
  hasEntered: false
};

export const initialState = {
  ssrTracks: [],
  profile: "PORT-Generic",
  favoritesTracks: [],
  featureToggles: [],
  greyhoundsProfiles: [
    "PORT-FL",
    "PORT-OR",
    "PORT-WY",
    "PORT-CO",
    "PORT-CT",
    "PORT-MA",
    "PORT-AR",
    "PORT-ZZ"
  ],
  loading: false,
  shouldUpdate: true,
  hasEntered: false
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "MHN_LOADING": {
      return {
        ...state,
        loading: action.payload
      };
    }
    case "MHN_REQUEST_PENDING": {
      return { ...state, loading: true };
    }
    case "MHN_REQUEST_SUCCESS_NO_RESULTS": {
      return {
        ...state,
        ssrTracks: []
      };
    }
    case "MHN_REQUEST_SUCCESS": {
      return {
        ...state,
        ssrTracks: action.payload
      };
    }
    case "MHN_REQUEST_FAIL": {
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
