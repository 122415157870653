// @flow
export type State = {
  prevPath: string,
  trackCode: string,
  raceNumber: string,
  isRaceDataShown: boolean,
  isBeta: boolean,
  isPromoWidgetModalOpen: boolean,
  subscription: ?number,
  showPAbetsSplashNotification: boolean
};

export const initialState = {
  prevPath: "",
  trackCode: "",
  raceNumber: "",
  isRaceDataShown: false,
  isBeta: false,
  isPromoWidgetModalOpen: false,
  promosList: [],
  seoTrackList: [],
  subscription: null,
  showPAbetsSplashNotification: false
};

type Action = {
  payload: boolean | string,
  type: string
};

export default function appReducer(
  state: State = initialState,
  action: Action
) {
  switch (action.type) {
    case "PROMOS_LIST_SET": {
      return { ...state, promosList: action.payload };
    }
    case "SET_BETA_OPTIN": {
      return { ...state, isBeta: action.payload };
    }
    case "SET_PROMO_WIDGET_MODAL_STATE": {
      return { ...state, isPromoWidgetModalOpen: action.payload };
    }
    case "SET_SEO_TRACK_LIST": {
      return { ...state, seoTrackList: action.payload };
    }
    default: {
      return state;
    }
  }
}
