// @flow
import type { Actions } from "./actions";

export type State = {
  tracks: string[],
  openFavoriteTracks: string[]
};

export const initialState = {
  tracks: [],
  openFavoriteTracks: []
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "TRACKSAZ_FAV_TOGGLE_TRACK": {
      return {
        ...state,
        openFavoriteTracks: action.payload.openFavoriteTracks
      };
    }
    default: {
      return state;
    }
  }
}
