// @flow
import type { Actions } from "./actions";

export type State = {
  showDownloadTransactions: boolean
};

export const initialState = {
  showDownloadTransactions: false
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "SHOW_HEADER_DOWNLOAD_TRANSACTIONS": {
      return {
        ...state,
        showDownloadTransactions: action.payload.showDownloadTransactions
      };
    }
    default: {
      return state;
    }
  }
}
