// @flow
import React, { type Element } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
// $FlowFixMe
import { type Location, HistoryRouterProps, Match } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import { findIndex } from "lodash";
import { withTheme } from "styled-components";

import type { Device, Product, MapFeatureToggles } from "@tvg/conf/src/types";
import { Containers } from "@tvg/atomic-ui/_static/BaseComponents";

import Router from "./Router";
import { footerItems } from "./components/Footer";

type SlideDirection =
  | "left"
  | "right"
  | "bottom"
  | "exit"
  | "enter"
  | "up"
  | "down";

const childPages = ["/racetracks", "/live"];
const fullPages = ["/wager-rewards"];

const pathIsWithinFooterItems = (pathName: string) =>
  findIndex(footerItems, ["href", pathName]);

const getPathName = (locationPath: string) =>
  locationPath !== "/" ? `/${locationPath.split("/")[1]}` : footerItems[0].href;

const isParentPage = (pathIndex: number, pathName: string): boolean =>
  pathIndex > -1 || pathName !== "/";

const isChildPage = (pathName: string): boolean =>
  childPages.some((childPage) => pathName.toLowerCase().includes(childPage));

const currentIsChildFromParent = (
  prevPath: string,
  currentPath: string
): boolean => {
  const prevPathIndex = pathIsWithinFooterItems(prevPath);
  const prevPathName = getPathName(prevPath);
  const currentPathName = getPathName(currentPath);
  return (
    isParentPage(prevPathIndex, prevPathName) && isChildPage(currentPathName)
  );
};

const currentIsParentFromChild = (
  prevPath: string,
  currentPath: string
): boolean => {
  const currentPathIndex = pathIsWithinFooterItems(currentPath);
  const prevPathName = getPathName(prevPath);
  const currentPathName = getPathName(currentPath);

  return (
    isParentPage(currentPathIndex, currentPathName) && isChildPage(prevPathName)
  );
};

const isFullPage = (path: string): boolean => {
  const pathName = getPathName(path);
  return fullPages.indexOf(pathName) >= 0;
};

const setSlideDirection = (
  currentPath: string,
  prevPath: string
): SlideDirection => {
  const prevPathName = `/${prevPath.split("/")[1]}`;
  const currentPathName = getPathName(currentPath);
  const prevPathIndex = pathIsWithinFooterItems(prevPathName);
  const currentPathIndex = pathIsWithinFooterItems(currentPathName);

  if (currentPathIndex > prevPathIndex) {
    return "right";
  }

  return "left";
};

const getExitClass = (
  slideDirection: SlideDirection,
  currentPath: string,
  prevPath: string
) => {
  const currentIsChildFromParentFlag = currentIsChildFromParent(
    prevPath,
    currentPath
  );
  const currentIsParentFromChildFlag = currentIsParentFromChild(
    prevPath,
    currentPath
  );

  if (currentIsChildFromParentFlag) {
    return "page-parent-exit";
  }

  if (currentIsParentFromChildFlag) {
    return "page-child-exit";
  }

  if (isFullPage(prevPath)) {
    return "page-exit-move-right";
  }

  switch (slideDirection) {
    case "right":
      return "page-exit-slide-left";
    case "left":
      return "page-exit-slide-right";
    case "down":
      return "page-exit-slide-down";
    case "exit":
      return "page-exit-slide-right";
    default:
      return "page-exit";
  }
};

const getEnterClass = (
  slideDirection: SlideDirection,
  currentPath: string,
  prevPath: string,
  enableAnims: boolean
) => {
  const currentIsChildFromParentFlag = currentIsChildFromParent(
    prevPath,
    currentPath
  );
  const currentIsParentFromChildFlag = currentIsParentFromChild(
    prevPath,
    currentPath
  );

  if (!enableAnims) {
    return "";
  }

  if (currentIsChildFromParentFlag) {
    return "page-child-enter";
  }

  if (currentIsParentFromChildFlag) {
    return "page-parent-enter";
  }

  if (isFullPage(currentPath)) {
    return "page-enter-move-right";
  }

  switch (slideDirection) {
    case "left":
      return "page-enter-slide-left";
    case "exit":
    case "down":
      return "page-enter";
    case "up":
      return "page-enter-slide-up";
    default:
      return "page-enter-slide-right";
  }
};

/**
  Modifies child props as it is exting, to switch class animations when changing direction
  See: https://reactcommunity.org/react-transition-group/#TransitionGroup-prop-childFactory
*/
const setChildProps = (
  child: Element<*>,
  slideDirection: SlideDirection,
  currentPath: string,
  prevPath: string
) => {
  const { classNames } = child.props;
  classNames.exit = getExitClass(slideDirection, currentPath, prevPath);

  return React.cloneElement(child, { ...child.props, props: { classNames } });
};

type Props = {
  match: Match,
  history: HistoryRouterProps,
  location: Location,
  prevPath: string,
  isLogged: boolean,
  device: Device,
  isFooterVisible: boolean,
  isHeaderVisible: boolean,
  isFullScreen: boolean,
  toggles: MapFeatureToggles,
  isBeta: boolean,
  product: Product,
  isFixed: boolean
};

const Main = ({
  location,
  history,
  match,
  prevPath,
  isLogged,
  device,
  product,
  isFooterVisible,
  isHeaderVisible,
  isFullScreen,
  toggles,
  isFixed,
  isBeta
}: Props) => {
  const slideDirection = setSlideDirection(location.pathname, prevPath);
  const enableAnims = device === "mobile";

  const timeoutAnimation = enableAnims ? 400 : 0;
  return (
    <Containers.MainSection
      device={device}
      isHeaderVisible={isHeaderVisible}
      isFooterVisible={isFooterVisible}
      isFullScreen={isFullScreen}
      isFixed={isFixed}
    >
      <TransitionGroup
        childFactory={(child) =>
          setChildProps(child, slideDirection, location.pathname, prevPath)
        }
      >
        <CSSTransition
          key={location.pathname.split("/")[1]}
          timeout={timeoutAnimation}
          classNames={{
            enter: getEnterClass(
              slideDirection,
              location.pathname,
              prevPath,
              enableAnims
            ),
            enterDone: "page-enter-done",
            enterActive: "page-enter",
            exitActive: "page-exit"
          }}
        >
          {(state) => (
            <Router
              match={match}
              history={history}
              location={location}
              state={state}
              prevPath={prevPath}
              isLogged={isLogged}
              device={device}
              product={product}
              toggles={toggles}
              isBeta={isBeta}
              isFixed={isFixed}
            />
          )}
        </CSSTransition>
      </TransitionGroup>
    </Containers.MainSection>
  );
};
// $FlowFixMe
export default withRouter(withTheme(Main));
