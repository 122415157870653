// @flow
import { type Actions } from "../actions/video";

export type Loading = boolean;

export const initialState = false;

export default function reducer(
  state: Loading = initialState,
  action: Actions
): Loading {
  switch (action.type) {
    case "GRAPH_REQUEST_PENDING":
    case "VIDEO_REQUEST_FETCHING": {
      return true;
    }
    case "GRAPH_REQUEST_FINISH":
    case "VIDEO_REQUEST_ERROR":
    case "VIDEO_REQUEST_SUCCESS": {
      return false;
    }
    default: {
      return state;
    }
  }
}
