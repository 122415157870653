// @flow

import React, { PureComponent, Fragment } from "react";
import { noop, bindAll } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import type { RaceStatusEnum, RaceListItem } from "@tvg/types/Race";
import buildRaceUrl, { buildLiveUrl } from "@tvg/formatter/url";

import type { ImportanceType } from "../../_molecule/RaceLink";
import RaceInfoInline from "../../_molecule/RaceInfoInline";

import buildColor from "../../_static/ColorPalette";
import {
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp
} from "../../_static/Icons/icons";
import Icon from "../../_static/Icons";
import {
  ArrowContainer,
  RaceMTP,
  RaceTitle,
  TitleMicro,
  RaceDescription,
  RaceNumber,
  RaceMicroNumber,
  GreyhoundText,
  RaceInfoContainer,
  buildTitleWrapper,
  buildRaceContainer,
  MTPContainer
} from "./styled-components";

export type Props = {
  /**
   * MTP value
   */
  mtp: number,
  /**
   * Posttime string value
   */
  postTime: string,
  /**
   * Status of the race
   */
  status: RaceStatusEnum,
  /**
   * Enables greyhound flag
   */
  isGreyhound: boolean,
  /**
   * Track name
   */
  trackName: string,
  /**
   * Track abbreviation
   */
  trackAbbr: string,
  /**
   * Race number
   */
  raceNumber: string,
  /**
   * Enables the smaller layout
   */
  isSmall: boolean,
  /**
   * Enables the micro layout
   */
  isMicro: boolean,
  /**
   * Callback to execute when clicked
   */
  onClickCallback: NullaryFn<mixed>,
  /**
   * If this element is RaceSelectionLine
   */
  isLink: boolean,
  /**
   * Description importance
   */
  importanceLevel?: ImportanceType,
  /**
   * Highlighted description of race
   */
  description?: string,
  /**
   * Inline race info
   */
  raceInfo?: RaceListItem[],
  /**
   * tvg channel selected if there is one
   */
  channel: string,
  /**
   * Arrow type
   */
  arrowType: "arrowUp" | "arrowDown" | "arrowLeft" | "arrowRight"
};

export const getIsRaceResult = (status: RaceStatusEnum): boolean =>
  status === "RO";

export default class RaceNavigation extends PureComponent<Props> {
  static defaultProps = {
    mtp: 0,
    postTime: "",
    status: "O",
    isGreyhound: false,
    trackAbbr: "",
    trackName: "",
    raceNumber: "1",
    isSmall: false,
    isMicro: false,
    onClickCallback: noop,
    isLink: false,
    isRaceResult: false,
    channel: "",
    importanceLevel: "0",
    description: "",
    raceInfo: [],
    arrowType: "arrowDown"
  };

  constructor(props: Props) {
    super(props);
    bindAll(this, "getArrowType");
  }

  getArrowType() {
    switch (this.props.arrowType) {
      case "arrowUp":
        return arrowUp;
      case "arrowLeft":
        return arrowLeft;
      case "arrowRight":
        return arrowRight;
      default:
        return arrowDown;
    }
  }

  renderContent = () => {
    if (this.props.isLink) {
      return (
        <Fragment>
          <RaceInfoContainer isLink>
            <RaceInfoInline
              title={`R${this.props.raceNumber}`}
              subTitle={this.props.channel !== "" ? this.props.trackName : ""}
              importanceLevel={this.props.importanceLevel}
              description={this.props.description}
              raceData={this.props.raceInfo}
              raceNumber={this.props.raceNumber}
              isRaceDataChangesShown
            />
          </RaceInfoContainer>
        </Fragment>
      );
    }

    if (this.props.isMicro) {
      return (
        <Fragment>
          <RaceInfoContainer isMicro>
            <RaceTitle>
              <TitleMicro> {this.props.trackAbbr}</TitleMicro>
              <RaceMicroNumber>{`- R${this.props.raceNumber}`}</RaceMicroNumber>
            </RaceTitle>
          </RaceInfoContainer>
        </Fragment>
      );
    }

    const Title = buildTitleWrapper(this.props.isSmall);
    return (
      <Fragment>
        <RaceInfoContainer>
          <RaceTitle>
            <Title>
              {this.props.isSmall ? this.props.trackAbbr : this.props.trackName}
            </Title>
            <RaceNumber>
              {this.props.isSmall && "-"} R{this.props.raceNumber}
            </RaceNumber>
          </RaceTitle>
          {this.props.isGreyhound && !this.props.isSmall && (
            <RaceDescription>
              <GreyhoundText>Greyhounds</GreyhoundText>
            </RaceDescription>
          )}
        </RaceInfoContainer>
        <ArrowContainer>
          <Icon
            size={16}
            icon={this.getArrowType()}
            color={buildColor("white", "100")}
          />
        </ArrowContainer>
      </Fragment>
    );
  };

  render() {
    const RaceContainer = buildRaceContainer(
      this.props.isLink,
      this.props.isSmall,
      this.props.isMicro,
      getIsRaceResult(this.props.status)
    );

    const raceContainerProps = this.props.isLink
      ? {
          to:
            this.props.channel !== ""
              ? buildLiveUrl(
                  this.props.trackAbbr,
                  this.props.trackName,
                  this.props.raceNumber,
                  this.props.channel
                )
              : buildRaceUrl(
                  this.props.trackAbbr,
                  this.props.trackName,
                  this.props.raceNumber
                )
        }
      : {
          qaLabel: "raceCardHeader-raceSelector"
        };

    return (
      <RaceContainer
        {...raceContainerProps}
        onTouchStart={noop}
        onClick={() => this.props.onClickCallback()}
      >
        <MTPContainer>
          <RaceMTP
            paddingless
            mtp={this.props.mtp}
            postTime={this.props.postTime}
            status={this.props.status}
            isSmall={this.props.isSmall}
            isMicro={this.props.isMicro}
            isLink={this.props.isLink}
            multipleLine={this.props.mtp > 60 && !this.props.isMicro}
            isHighlightedBellowThreshold={
              !this.props.isSmall && !this.props.isMicro
            }
          />
        </MTPContainer>
        {this.renderContent()}
      </RaceContainer>
    );
  }
}
