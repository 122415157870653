// @flow
import type { Location, RouterHistory } from "react-router-dom";
import type { Dispatch } from "redux";
import { find } from "lodash";
import { modalHashMap } from "../Hashes";

export const interceptor = (
  previousLocation: Location,
  currentLocation: Location,
  dispatch: Dispatch<*>,
  history: RouterHistory,
  isLogged: boolean,
  redirectedFromQuickToFullDeposit: boolean,
  myBetsStandaloneToggle: boolean,
  isLoginModalOpen: boolean,
  enablePawsQuickDeposits: boolean
) => {
  if (currentLocation.hash) {
    const url = `${currentLocation.pathname}${currentLocation.search}`;

    const previousHash =
      previousLocation && previousLocation.hash
        ? previousLocation.hash
        : undefined;

    const hash = find(
      modalHashMap({
        dispatch,
        history,
        location: url,
        isLogged,
        redirectedFromQuickToFull: redirectedFromQuickToFullDeposit,
        previousHash,
        myBetsStandaloneToggle,
        isLoginModalOpen,
        enablePawsQuickDeposits
      }),
      (h) => h.hash === currentLocation.hash
    );
    if (hash) hash.call();
  }
};

export default {
  interceptor
};
