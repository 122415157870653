// @flow
import React, { Component, type Node } from "react";
import { get, isEqual, noop } from "lodash";
import { Link } from "react-router-dom";
import type { Brand } from "@tvg/types/Product";
import type { RaceStatusEnum, RaceVideoFeedback } from "@tvg/types/Race";
import type { NullaryFn, BinaryFn } from "@tvg/types/Functional";
import tvgConf from "@tvg/conf";

import type { HeaderLayoutType } from "./types";
import NotificationBubble from "../../_atom/NotificationBubble";
import { type QuickDepositType } from "../../_molecule/QuickDepositButton";
import VideoButton from "../../_molecule/VideoButton";
import RaceNavigation from "../../_organism/RaceNavigation";
import BalanceButton from "../../_atom/BalanceButton";

import buildColor from "../../_static/ColorPalette";
import Logo from "../../_static/Logos";
import { arrowBack, lock, envelope, email } from "../../_static/Icons/icons";
import {
  Container,
  HeaderContent,
  LoginSignup,
  LoginSignupInner,
  LockIcon,
  LogoNavigationContainer,
  RaceNavigationContainer,
  VideoButtonContainer,
  AnimationContainer,
  HeaderLabelContainer,
  HeaderLabel,
  ActionToolsContainer,
  ContentCardsInboxContainer,
  ButtonTransparent,
  ButtonLinkStyled,
  ChildContainer,
  HeaderDescriptionContainer,
  HeaderDescription
} from "./styled-components";
import Icon from "../../_static/Icons";

type RaceDataType = {
  /**
   * MTP value ex: 3
   */
  mtp: number,
  /**
   * Race number value ex: 1
   */
  raceNumber: string,
  /**
   * Track abbreviation ex: GB1
   */
  trackAbbr: string,
  /**
   * Track name ex: GB1
   */
  trackName: string,
  /**
   * Status of the race
   */
  status: RaceStatusEnum,
  /**
   * Posttime string value ex: 2017-10-29T09:28:00.000Z
   */
  postTime: string
};

type LoginSignupType = {
  signUpUrl: string,
  loginUrl: string
};

export type Props = {
  /**
   * Header logo mode
   */
  logoMod: string,
  /**
   * User is logged
   */
  isLogged: boolean,
  /**
   * open race navigation modal
   */
  openRaceNavigation: NullaryFn<mixed>,
  /**
   * Show short race navigation
   */
  isNavigationShown: boolean,
  /**
   * Show button for track video
   */
  isVideoButtonShown: boolean,
  /**
   * Show action tools
   */
  isActionToolsShown: boolean,
  /**
   * Show login actions
   */
  isLogActionsShown: boolean,
  /**
   * Logo Brand
   */
  brand: Brand,
  /**
   * Quick Deposit Data
   */
  quickDeposit: QuickDepositType,
  /**
   * Quick Deposit Data
   */
  raceData: RaceDataType,
  /**
   * Quick Deposit Data
   */
  loginSignup: LoginSignupType,
  /**
   * Quick Deposit Data
   */
  onLogoClick: NullaryFn<void>,
  /**
   * changes the header layout to the version for tablet (taller) or mobile (normal)
   */
  layout: HeaderLayoutType,
  /**
   * state of the video button
   */
  videoFeedBack: RaceVideoFeedback,
  /**
   * on video button click callback
   */
  onVideoButton: NullaryFn<void>,
  /**
   * on back button click callback
   */
  onBackClick: NullaryFn<void>,
  /**
   * the prevPath for the backButton
   */
  prevPath: string,
  /**
   * the header label in case of backButton
   */
  label: string,
  /**
   * Content card inbox shown
   */
  isContentCardsInboxShown: boolean,
  /**
   * Unviewed content card counter
   */
  unviewedContentCardsCount: number,
  /**
   * Header class
   */
  headerClass: string,
  /**
   * OpenInbox callback
   */
  openInbox: BinaryFn<boolean, number, void>,
  /**
   * Center title on header
   */
  isTitleCenter?: boolean,

  children: Node,
  /**
   * Description below title on header
   */
  description?: string
};

export class Header extends Component<Props> {
  static defaultProps = {
    logoMod: "",
    isLogged: false,
    isNavigationShown: false,
    isVideoButtonShown: false,
    isActionToolsShown: false,
    isLogActionsShown: true,
    isTitleCenter: false,
    onLogoClick: noop,
    openRaceNavigation: noop,
    quickDeposit: {
      balance: 0,
      isBalanceShown: true,
      url: "/"
    },
    brand: "tvg",
    loginSignup: {
      signUpUrl: "/",
      loginUrl: "/"
    },
    raceData: {
      trackAbbr: "",
      trackName: "",
      raceNumber: "",
      mtp: 0,
      postTime: "",
      status: "O"
    },
    layout: "normal",
    videoFeedBack: "NOT_AVAILABLE_NO_REPLAY",
    onVideoButton: noop,
    onBackClick: noop,
    prevPath: "/",
    label: "",
    unviewedContentCardsCount: 0,
    isContentCardsInboxShown: false,
    headerClass: "",
    openInbox: noop,
    description: ""
  };

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(nextProps, this.props);
  }

  openCloseInbox = () => {
    this.props.openInbox(true, this.props.unviewedContentCardsCount);
  };

  renderLogo = () => {
    const isBrandTVG = this.props.brand === "tvg";
    let logoHeight = 18;
    if (
      this.props.headerClass === "logo-highlighted" &&
      tvgConf().device !== "mobile"
    ) {
      logoHeight = 28;
    } else if (isBrandTVG) {
      logoHeight = 32;
    }
    return (
      <Link to="/" onClick={this.props.onLogoClick}>
        <Logo
          brand={isBrandTVG ? "tvgPoweredByFanduel" : this.props.brand}
          data-qa-label="tvgLogo"
          height={logoHeight}
          isDark={
            this.props.headerClass === "logo-highlighted" ||
            this.props.headerClass === "logo-login"
          }
        />
      </Link>
    );
  };

  renderBackButton = () => (
    <HeaderLabelContainer
      to={this.props.prevPath}
      onClick={this.props.onBackClick}
      data-qa-label="headerLabel-back"
      center={
        this.props.isTitleCenter ? this.props.isTitleCenter.toString() : "false"
      }
    >
      <Icon
        icon={arrowBack}
        color={
          this.props.headerClass === "monochromatic" ||
          this.props.headerClass === "lowercaseMonochromatic"
            ? buildColor("grey", "900")
            : buildColor("white", "100")
        }
        size={16}
        qaLabel="headerLabel-back-icon"
      />
      <HeaderLabel
        headerClass={this.props.headerClass}
        data-qa-label="headerLabel-back-label"
      >
        {this.props.label}
      </HeaderLabel>
    </HeaderLabelContainer>
  );

  renderVideoActionTools = () => (
    <LogoNavigationContainer
      isNavigationShown={this.props.isNavigationShown}
      className={this.props.headerClass}
      isMobile={tvgConf().device === "mobile"}
      isTitleCenter={this.props.isTitleCenter}
    >
      {!this.props.label && this.props.logoMod !== "navigation"
        ? this.renderLogo()
        : this.renderBackButton()}
      {typeof window !== "undefined" && (
        <AnimationContainer>
          <RaceNavigationContainer
            isNavigationShown={this.props.isNavigationShown}
          >
            <RaceNavigation
              {...this.props.raceData}
              onClickCallback={this.props.openRaceNavigation}
              isSmall
            />
          </RaceNavigationContainer>
          <VideoButtonContainer
            isVideoButtonShown={this.props.isVideoButtonShown}
          >
            <VideoButton
              small
              feedback={this.props.videoFeedBack}
              onClick={this.props.onVideoButton}
            />
          </VideoButtonContainer>
        </AnimationContainer>
      )}
    </LogoNavigationContainer>
  );

  renderLoggedOutActionTools = () => (
    <LoginSignup data-qa-label="header-sessionButtons">
      <ButtonTransparent
        type={
          this.props.headerClass === "logo-login"
            ? "secondary"
            : "secondary_alt"
        }
        url={this.props.loginSignup.loginUrl}
        isGrey={this.props.headerClass === "logo-login"}
        qaLabel="openLoginButton"
      >
        <LockIcon
          icon={lock}
          size={16}
          color={
            this.props.headerClass === "monochromatic" ||
            this.props.headerClass === "lowercaseMonochromatic" ||
            this.props.headerClass === "logo-login"
              ? buildColor("grey", "900")
              : buildColor("white", "100")
          }
        />
        <LoginSignupInner>
          {this.props.headerClass === "logo-login" ? "Login" : "Log in"}
        </LoginSignupInner>
      </ButtonTransparent>

      {this.props.headerClass !== "logo-login" && (
        <ButtonLinkStyled
          tag="link"
          url={this.props.loginSignup.signUpUrl}
          qaLabel="signUpButton"
        >
          Sign up
        </ButtonLinkStyled>
      )}
    </LoginSignup>
  );

  renderLoggedInActionTools = () => (
    <ActionToolsContainer>
      <BalanceButton
        url={this.props.quickDeposit.url}
        balanceAmount={this.props.quickDeposit.balance}
        onBalanceClick={this.props.quickDeposit.onClick}
        isBalanceShown={this.props.quickDeposit.isBalanceShown}
        device="mobile"
      />
      {this.props.isContentCardsInboxShown && (
        <ContentCardsInboxContainer onClick={this.openCloseInbox}>
          <NotificationBubble
            iconSize={20}
            icon={tvgConf().device === "desktop" ? email : envelope}
            counter={this.props.unviewedContentCardsCount}
            qaLabel="content-cards-inbox"
          />
        </ContentCardsInboxContainer>
      )}
    </ActionToolsContainer>
  );

  renderHeaderDescription = () => (
    <HeaderDescriptionContainer>
      <HeaderDescription>{this.props.description}</HeaderDescription>
    </HeaderDescriptionContainer>
  );

  render() {
    let isRg = false;
    if (typeof window !== "undefined") {
      isRg = location.pathname.includes("responsible-gaming");
    }

    return (
      <Container>
        <HeaderContent
          data-qa-label="header"
          layout={this.props.layout}
          className={this.props.headerClass}
          hasDescription={!!this.props.description}
        >
          {this.renderVideoActionTools()}
          {this.props.children && (
            <ChildContainer>{this.props.children}</ChildContainer>
          )}
          {get(this.props, "isLogged", false)
            ? get(this.props, "isActionToolsShown", true) &&
              this.renderLoggedInActionTools()
            : get(this.props, "isLogActionsShown") &&
              !isRg &&
              this.renderLoggedOutActionTools()}
        </HeaderContent>
        {this.props.description && this.renderHeaderDescription()}
      </Container>
    );
  }
}

export default Header;
