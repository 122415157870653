// @flow
import _ from "lodash";
import type { IOSActions } from "../../actions/ios";

type TouchIdTypeEnum =
  | 0 // no touch id or face id
  | 1 // touch id
  | 2; // face id

export type State = {
  userChangingTouchId: boolean,
  isNotificationsEnabled: boolean,
  touchId: {
    accountId: string,
    token: string,
    touchIdEnabled: boolean
  },
  touchIdError: {
    errorCode: number,
    message: string
  },
  init: {
    type: string,
    message: string,
    url: string,
    touchIdAvailable: boolean,
    touchIdEnabled: boolean,
    touchIdType: TouchIdTypeEnum,
    accountId: number,
    launchTimestamp: number,
    loadTimestamp: number,
    error: string,
    meta: string
  }
};

export const initialState = {
  userChangingTouchId: false,
  isNotificationsEnabled: false,
  touchId: {
    accountId: "",
    token: "",
    touchIdEnabled: false,
    touchIdType: 0
  },
  touchIdError: {
    errorCode: 0,
    message: ""
  },
  init: {
    type: "",
    message: "",
    appVersion: "",
    url: "",
    touchIdAvailable: false,
    touchIdEnabled: false,
    touchIdType: 0,
    accountId: 0,
    launchTimestamp: 0,
    loadTimestamp: 0,
    error: "",
    meta: ""
  }
};

const iOSEventsReducer = (state: State = initialState, action: IOSActions) => {
  switch (action.type) {
    case "IOS_INIT_RESPONSE": {
      const init = _.merge(state.init, action.payload);
      return {
        ...state,
        init,
        touchId: {
          ...state.touchId,
          accountId: init.accountId,
          touchIdEnabled: init.touchIdEnabled,
          touchIdAvailable: init.touchIdAvailable,
          touchIdType: init.touchIdType
        }
      };
    }
    case "IOS_TOUCH_ID_RESPONSE": {
      const touchId = _.merge(state.touchId, action.payload);
      return { ...state, touchId };
    }
    case "IOS_TOUCH_ID_ERROR": {
      const touchIdError = _.merge(state.touchIdError, action.payload);
      return { ...state, touchIdError };
    }
    case "IOS_TOUCH_ID_TOGGLE": {
      return {
        ...state,
        touchId: { ...state.touchId, touchIdEnabled: action.payload }
      };
    }
    case "IOS_TOUCH_ID_CHANGE": {
      return {
        ...state,
        userChangingTouchId: action.payload.isChanging
      };
    }
    case "IOS_NOTIFICATIONS_STATE": {
      return {
        ...state,
        isNotificationsEnabled: action.payload.isNotificationsEnabled
      };
    }
    default: {
      return state;
    }
  }
};

export default iOSEventsReducer;
