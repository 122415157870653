// @flow

import React, { PureComponent } from "react";
import { noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import Icon from "../../_static/Icons";
import { optedin, promosolid } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import {
  OptedInAlt,
  OptedIn,
  Promos,
  PromosExternal
} from "./styled-components";

type Props = {
  /**
   * Promotions relative path URL
   */
  promoPath: string,
  /**
   * qaLabel for test
   */
  qaLabel: string,
  /**
   * qaLabel for test
   */
  isOptedIn: boolean,
  /* callback for gtm usage
   */
  onClick: NullaryFn<void>,
  /* boolean to determine wether to use alternative button
   */
  useAltLink: boolean,
  /* boolean to determine wether to render plain ref
   */
  isExternal?: boolean
};

export default class PromosButton extends PureComponent<Props> {
  static defaultProps = {
    promoPath: "",
    qaLabel: "",
    isOptedIn: false,
    useAltLink: false,
    onClick: noop,
    isExternal: false
  };

  render() {
    const OptedInComp = this.props.useAltLink ? OptedInAlt : OptedIn;
    const LinkOrRef = this.props.isExternal ? PromosExternal : Promos;
    const Button = this.props.isOptedIn ? OptedInComp : LinkOrRef;

    return (
      <Button
        to={this.props.promoPath}
        onClick={this.props.onClick}
        {...(this.props.qaLabel !== ""
          ? {
              "data-qa-label": this.props.qaLabel
            }
          : {})}
      >
        <Icon
          icon={this.props.isOptedIn ? optedin : promosolid}
          color={buildColor("white", "100")}
        />
        {this.props.isOptedIn ? "Opted-In" : "Promo"}
      </Button>
    );
  }
}
