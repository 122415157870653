// @flow
import type { Element } from "react";
import RG_OPTIONS from "@tvg/types/ResponsibleGaming";
import type { SetHandicappingStoreModalHeaderAction } from "@tvg/shared-actions/HandicappingStore";
import type { Actions } from "../../actions/modals";
import type { DepositType, PasswordRecoveryType } from "../../types";

export type State = {
  loginOpen: boolean,
  loginComponent: Element<*> | null,
  promosOpen: boolean,
  emailReferralOpen: boolean,
  deposits: {
    open: boolean,
    title: string,
    type: DepositType,
    redirectedFromQuickToFull?: boolean
  },
  prf: {
    open: boolean,
    title: string,
    type: PasswordRecoveryType
  },
  handicappingStore: {
    isOpen: boolean,
    isModalHasHeader: boolean,
    url: string
  },
  isModalOpen: boolean,
  navigationOpen: boolean,
  betPreferencesOpen: boolean,
  termsContent: string,
  isSightlineRedirectModalOpen: boolean,
  pendingWithdrawalsOpen: boolean,
  featureTogglesOpen: boolean,
  responsibleGaming: {
    isOpen: boolean,
    state: string | null,
    status: string,
    type: string,
    isProcessing: boolean,
    timeoutPeriod?: {
      period: number,
      endDate: string
    },
    fundingMethod: string,
    dailyLimit: {
      new: number,
      current: number | null,
      appliedDate: string | null
    }
  }
};

export const initialState = {
  loginOpen: false,
  loginComponent: null,
  promosOpen: false,
  emailReferralOpen: false,
  deposits: {
    open: false,
    title: "Deposits",
    type: "deposit",
    redirectedFromQuickToFull: false
  },
  prf: {
    open: false,
    title: "Forgot Credentials",
    type: "forgotcredentials"
  },
  isModalOpen: false,
  navigationOpen: false,
  betPreferencesOpen: false,
  pendingWithdrawalsOpen: false,
  featureTogglesOpen: false,
  handicappingStore: {
    isOpen: false,
    isModalHasHeader: true,
    url: ""
  },
  isSightlineRedirectModalOpen: false,
  termsContent: "",
  responsibleGaming: {
    isOpen: false,
    state: null,
    status: "confirm",
    type: RG_OPTIONS.DEPOSIT_LIMIT,
    isProcessing: false,
    fundingMethod: "",
    dailyLimit: {
      new: 0,
      current: null,
      appliedDate: null
    }
  }
};

const modalReducer = (
  state: State = initialState,
  action: Actions | SetHandicappingStoreModalHeaderAction
): State => {
  switch (action.type) {
    case "OPEN_LOGIN_MODAL_V1": {
      return {
        ...state,
        loginOpen: true,
        loginComponent: action.payload,
        isModalOpen: true
      };
    }
    case "CLOSE_LOGIN_MODAL_V1": {
      return {
        ...state,
        loginOpen: false,
        isModalOpen: false
      };
    }
    case "OPEN_PROMOS_MODAL": {
      return { ...state, promosOpen: true, isModalOpen: true };
    }
    case "CLOSE_PROMOS_MODAL": {
      return { ...state, promosOpen: false, isModalOpen: false };
    }
    case "OPEN_EMAIL_REFERRAL_MODAL": {
      return { ...state, emailReferralOpen: true, isModalOpen: true };
    }
    case "CLOSE_EMAIL_REFERRAL_MODAL": {
      return { ...state, emailReferralOpen: false, isModalOpen: false };
    }
    case "OPEN_PASSWORD_RECOVERY_MODAL": {
      return {
        ...state,
        prf: {
          open: true,
          title: action.payload.title,
          type: action.payload.type
        },
        isModalOpen: true
      };
    }
    case "CLOSE_PASSWORD_RECOVERY_MODAL": {
      return {
        ...state,
        prf: { ...state.prf, open: false },
        isModalOpen: false
      };
    }
    case "OPEN_DEPOSITS_MODAL": {
      return {
        ...state,
        deposits: {
          ...state.deposits,
          open: true,
          title: action.payload.title,
          type: action.payload.type
        },
        isModalOpen: true
      };
    }
    case "CLOSE_DEPOSITS_MODAL": {
      return {
        ...state,
        deposits: {
          ...state.deposits,
          open: false,
          redirectedFromQuickToFull: false
        },
        isModalOpen: false
      };
    }
    case "OPEN_WITHDRAW_MODAL": {
      return { ...state, withdrawOpen: true, isModalOpen: true };
    }
    case "CLOSE_WITHDRAW_MODAL": {
      return { ...state, withdrawOpen: false, isModalOpen: false };
    }
    case "OPEN_NAVIGATION_MODAL": {
      return {
        ...state,
        navigationOpen: true,
        termsContent: action.payload,
        isModalOpen: true
      };
    }
    case "CLOSE_NAVIGATION_MODAL": {
      return {
        ...state,
        navigationOpen: false,
        termsContent: action.payload,
        isModalOpen: false
      };
    }
    case "OPEN_PENDING_WITHDRAWALS_MODAL": {
      return { ...state, pendingWithdrawalsOpen: true, isModalOpen: true };
    }
    case "CLOSE_PENDING_WITHDRAWALS_MODAL": {
      return { ...state, pendingWithdrawalsOpen: false, isModalOpen: false };
    }
    case "OPEN_BET_PREFERENCES_MODAL": {
      return { ...state, betPreferencesOpen: true, isModalOpen: true };
    }
    case "CLOSE_BET_PREFERENCES_MODAL": {
      return { ...state, betPreferencesOpen: false, isModalOpen: false };
    }
    case "OPEN_FEATURE_TOGGLES_MODAL": {
      return { ...state, featureTogglesOpen: true, isModalOpen: true };
    }
    case "CLOSE_FEATURE_TOGGLES_MODAL": {
      return { ...state, featureTogglesOpen: false, isModalOpen: false };
    }
    case "OPEN_HANDICAPPING_STORE_MODAL": {
      return {
        ...state,
        handicappingStore: {
          ...state.handicappingStore,
          isOpen: true
        },
        isModalOpen: true
      };
    }
    case "CLOSE_HANDICAPPING_STORE_MODAL": {
      return {
        ...state,
        handicappingStore: {
          ...state.handicappingStore,
          isOpen: false,
          url: ""
        },
        isModalOpen: false
      };
    }
    case "SET_HANDICAPPING_STORE_MODAL_URL": {
      return {
        ...state,
        handicappingStore: {
          ...state.handicappingStore,
          url: action.payload.url
        },
        isModalOpen: false
      };
    }
    case "SET_HANDICAPPING_STORE_MODAL_HEADER": {
      return {
        ...state,
        handicappingStore: {
          ...state.handicappingStore,
          isModalHasHeader: action.payload.isModalHasHeader
        }
      };
    }
    case "OPEN_SUPPORT_MODAL": {
      return {
        ...state,
        supportOpen: true,
        isModalOpen: true
      };
    }
    case "CLOSE_SUPPORT_MODAL": {
      return { ...state, supportOpen: false, isModalOpen: false };
    }

    case "OPEN_CONTENT_CARDS_MODAL": {
      return { ...state, contentCardsOpen: true, isModalOpen: true };
    }
    case "CLOSE_CONTENT_CARDS_MODAL": {
      return { ...state, contentCardsOpen: false, isModalOpen: false };
    }

    case "FIX_DEPOSIT_MODAL_SIGHTLINE_PROMPT": {
      return {
        ...state,
        isSightlineRedirectModalOpen:
          action.payload.isSightlineRedirectModalOpen
      };
    }
    case "QUICK_DEPOSIT_FULL_DEPOSIT_REDIRECT":
      return {
        ...state,
        deposits: {
          ...state.deposits,
          redirectedFromQuickToFull: true
        }
      };
    case "RG_MODAL_DEPOSIT_LIMIT_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.DEPOSIT_LIMIT,
          dailyLimit: {
            new: action.payload.newLimit,
            current: action.payload.currentLimit,
            appliedDate: action.payload.appliedDate
          }
        }
      };
    case "RG_MODAL_FUNDING_CONTROL_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.FUNDING_CONTROLS,
          fundingMethod: action.payload.fundingMethod
        }
      };
    case "RG_MODAL_TIMEOUT_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.TIMEOUT,
          timeoutPeriod: {
            period: action.payload.period,
            endDate: action.payload.endDate
          }
        }
      };
    case "RG_MODAL_SELF_EXCLUSION_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.SELF_EXCLUSION,
          timeoutPeriod: {
            period: action.payload.period,
            endDate: action.payload.endDate
          }
        }
      };
    case "RG_MODAL_SUSPENSION_OPEN":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "pending",
          isOpen: true,
          status: "confirmation",
          type: RG_OPTIONS.SUSPEND
        }
      };
    case "RG_MODAL_SUBMIT":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "submitted"
        }
      };
    case "RG_MODAL_CANCEL":
      return {
        ...state,
        responsibleGaming: initialState.responsibleGaming
      };
    case "RG_MODAL_FINISHED":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "finished",
          isFinished: true,
          status: "success"
        }
      };
    case "RG_MODAL_ERROR":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          state: "error",
          status: "error"
        }
      };
    case "RG_MODAL_CLOSE":
      return {
        ...state,
        responsibleGaming: initialState.responsibleGaming
      };
    case "RG_MODAL_PROCESSING":
      return {
        ...state,
        responsibleGaming: {
          ...state.responsibleGaming,
          isProcessing: action.payload.isProcessing
        }
      };
    default: {
      return state;
    }
  }
};

export default modalReducer;
