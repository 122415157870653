// @flow
import type { RegionData } from "@tvg/geolocation";

export type GeoLocationGetAction = {
  type: "GEO_LOCATION_GET",
  payload: RegionData
};

export type GeoActions = GeoLocationGetAction;

export const getGeoLocation = (
  locationData: RegionData
): GeoLocationGetAction => ({
  type: "GEO_LOCATION_GET",
  payload: locationData
});

export default getGeoLocation;
