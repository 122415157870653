// @flow
import type { TabKey } from "@tvg/atomic-ui/_templates/PoolsNInfo";
import type { Actions } from "../actions/poolsNInfo";

export type PoolsNInfo = {
  selectedPoolsNInfoTab: TabKey,
  isPoolsNInfoModalOpen: boolean
};

export const initialState = {
  selectedPoolsNInfoTab: "infochanges",
  isPoolsNInfoModalOpen: false
};

export default function reducer(
  state: PoolsNInfo = initialState,
  action: Actions
): PoolsNInfo {
  switch (action.type) {
    case "POOLS_N_INFO_TAB": {
      return {
        ...state,
        selectedPoolsNInfoTab: action.payload
      };
    }

    case "TOGGLE_POOLS_N_INFO_MODAL": {
      return {
        ...state,
        isPoolsNInfoModalOpen: action.payload,
        selectedPoolsNInfoTab: action.payload
          ? state.selectedPoolsNInfoTab
          : "infochanges"
      };
    }

    default: {
      return state;
    }
  }
}
