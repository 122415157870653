// @flow
import type { OnboardingRoute, Tutorial } from "@tvg/types/OnBoarding";
import type { Actions } from "@tvg/shared-actions/OnBoardingActions";

export type State = {
  tutorials: OnboardingRoute[],
  isPromptShown: boolean,
  isTutorialShown: boolean,
  activeTutorial: Tutorial | null,
  redirect: boolean | null
};

export const initialState = {
  tutorials: [],
  isPromptShown: false,
  isTutorialShown: false,
  activeTutorial: null,
  redirect: false
};

export default function capiReducer(
  state: State = initialState,
  action: Actions
) {
  switch (action.type) {
    case "ONBOARDING_SET_TUTORIAL_ROUTES": {
      return { ...state, tutorials: action.payload.tutorials };
    }

    case "ONBOARDING_OPEN_PROMPT": {
      return {
        ...state,
        isPromptShown: true,
        isTutorialShown: false,
        activeTutorial: action.payload.tutorial
      };
    }

    case "ONBOARDING_CLOSE_PROMPT": {
      return { ...state, isPromptShown: false, activeTutorial: null };
    }

    case "ONBOARDING_OPEN_TUTORIAL": {
      return {
        ...state,
        isTutorialShown: true,
        isPromptShown: false,
        activeTutorial: action.payload.tutorial,
        redirect: action.payload.redirect
      };
    }

    case "ONBOARDING_CLOSE_TUTORIAL": {
      return { ...state, isTutorialShown: false, activeTutorial: null };
    }

    default: {
      return state;
    }
  }
}
