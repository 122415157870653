// @flow

import { attempt, get, isFunction } from "lodash";
import {
  type RevalidateLocationActions,
  openLocationRevalidate,
  setLocationRevalidateFailure
} from "@tvg/revalidate-location/actions";
import mediator from "@tvg/mediator";
import GeoComply from "@tvg/login-service/services/geoComply";
import TvgGeolocation, { type RegionData } from "@tvg/geolocation";
import {
  type LocationSplashActions,
  openLocationSplash,
  setLocationLoading,
  setLocationRequired,
  setLocationUnauthorized,
  setLocationUnknownError,
  setOnLocationGet
} from "@tvg/location-splash/actions";
import type { UnaryFn, NullaryFn } from "@tvg/types/Functional";
import type { LoginPayload, Props } from "@tvg/types/Login";
import type { Dispatch } from "redux";
import { requestLocation } from "@tvg/location-splash/splashBuilder";
import TVGConf from "@tvg/conf";
import isMobile from "@tvg/utils/mobileUtils";
import type { UserData } from "../actions/login";
import { getGeoLocation } from "../actions/geo";

const Geolocation = new TvgGeolocation();
const geoComply = new GeoComply();
let geoTimeout = null;
const DEFAULT_TIMEOUT = 60000;
let previousTimeout;

export const getStatesWithLocationRequired = (message: string): string[] =>
  message ? JSON.parse(message).map((state) => state.toUpperCase()) : [];

export const checkMobileLocation = (
  geolocation: TvgGeolocation,
  payload: LoginPayload,
  splashError: string,
  isLocationSplashOpen: boolean,
  isLocationRequired: boolean,
  dispatch: Dispatch<LocationSplashActions>,
  openLogin: UnaryFn<LoginPayload, void>
) => {
  if (
    isMobile(TVGConf().product) &&
    (!get(geolocation, "location.latitude") ||
      !get(geolocation, "location.longitude") ||
      !get(geolocation, "state"))
  ) {
    mediator.ios.dispatch({ type: "ASK_LOCATION" });
  } else if (isMobile(TVGConf().product)) {
    TvgGeolocation.validateState(get(geolocation, "state"))
      .then((blacklisted) => {
        if (
          (blacklisted && splashError !== "BLOCKED_COUNTRY") ||
          (blacklisted &&
            splashError === "BLOCKED_COUNTRY" &&
            !isLocationSplashOpen &&
            !isLocationRequired)
        ) {
          dispatch(setLocationRequired(true));
          dispatch(setLocationUnauthorized());
          dispatch(openLocationSplash());
        }
        if (!blacklisted) {
          openLogin(payload);
        }
      })
      .catch(() => {
        dispatch(setLocationRequired(true));
        dispatch(setLocationUnknownError(true));
        dispatch(openLocationSplash());
      });
  } else {
    openLogin(payload);
  }
};

export const setNewTimeoutRevalidateLocation = (
  accountId: number,
  timeoutInterval: number,
  dispatch: Dispatch<RevalidateLocationActions>,
  geolocateInterval: number | null = null,
  retry: number = 0
) => {
  geoTimeout = setTimeout(() => {
    // eslint-disable-next-line
    revalidateLocation(geolocateInterval, accountId, retry, dispatch);
  }, timeoutInterval);
};

export const revalidateLocationError = (
  retry: number,
  accountId: number,
  dispatch: Dispatch<RevalidateLocationActions>
) => {
  if (retry < 4) {
    setNewTimeoutRevalidateLocation(
      accountId,
      DEFAULT_TIMEOUT,
      dispatch,
      null,
      retry + 1
    );

    dispatch(openLocationRevalidate());
  } else {
    dispatch(setLocationRevalidateFailure());
    mediator.base.dispatch({
      type: "TVG_LOGIN:DO_LOGOUT"
    });
  }
};

export const removeTimeout = () => {
  if (geoTimeout !== null) {
    clearTimeout(geoTimeout);
  }
};

export const revalidateLocation = (
  geolocateInterval: number | null,
  accountId: number,
  retry: number = 0,
  dispatch: Dispatch<RevalidateLocationActions>
) => {
  if (geoTimeout !== null) {
    clearTimeout(geoTimeout);
  }

  if (geolocateInterval) {
    setNewTimeoutRevalidateLocation(accountId, geolocateInterval, dispatch);
  } else {
    geoComply
      .getGeoPacket(accountId, "revalidate")
      .then(({ geo }) => {
        geoComply
          .validateGeoPacket(accountId, geo)
          .then(({ data: { geolocateIn } }) => {
            const millisecondsGeolocateIn = geolocateIn * 1000;
            setNewTimeoutRevalidateLocation(
              accountId,
              millisecondsGeolocateIn,
              dispatch
            );
            previousTimeout = millisecondsGeolocateIn;
          })
          .catch(() => {
            revalidateLocationError(retry, accountId, dispatch);
          });
      })
      .catch((error) => {
        if (error.message === geoComply.softErrorMessage) {
          setNewTimeoutRevalidateLocation(accountId, previousTimeout, dispatch);
        } else {
          revalidateLocationError(retry, accountId, dispatch);
        }
      });
  }
};

export const handleRevalidateLocation = (
  user: UserData,
  geopacketUsage: boolean,
  dispatch: Dispatch<RevalidateLocationActions>
) => {
  const geolocateIn = get(user, "geolocateIn");
  /*
   * geopacketUsage is a capi feat toggle to validate location for 4NJBets
   */ if (
    geopacketUsage &&
    user.logged &&
    geolocateIn &&
    TVGConf().brand === "4njbets"
  ) {
    revalidateLocation(
      geolocateIn * 1000,
      get(user, "user.accountNumber"),
      0,
      dispatch
    );
  }
};

export const requestLocationWeb = (user: UserData, props: Props) => {
  if (!isMobile(TVGConf().product)) {
    const userState =
      get(user, "user.homeAddress.stateAbbr", null) ||
      get(user, "user.homeAddress.state", null) ||
      get(user, "user.homeState", null);
    const locationRequired =
      userState &&
      getStatesWithLocationRequired(get(props, "locationRequired")).includes(
        userState.toUpperCase()
      ) &&
      get(props, "history.location.pathname") !== "/redirectEngine";
    props.dispatch(setLocationRequired(locationRequired));
    if (locationRequired) {
      if (attempt(() => window.localStorage.getItem("shownLocationSplash"))) {
        // $FlowFixMe
        requestLocation(props.dispatch, Geolocation, props.onLocationGet);
      } else {
        props.dispatch(openLocationSplash());
      }
    } else if (props.onLocationGet && isFunction(props.onLocationGet)) {
      props.onLocationGet();
      props.dispatch(setOnLocationGet(null));
    }
  }
};

export const handleIOSLocation = (
  state: string,
  callback: UnaryFn<boolean, void>,
  dispatch: Dispatch<LocationSplashActions>
) => {
  TvgGeolocation.validateState(state)
    .then((blacklisted) => {
      if (blacklisted) {
        dispatch(setLocationRequired(true));
        dispatch(setLocationUnauthorized());
        dispatch(openLocationSplash());
      }
      if (callback) {
        callback(blacklisted);
      }
    })
    .catch(() => {
      dispatch(setLocationRequired(true));
      dispatch(setLocationUnknownError(true));
      dispatch(openLocationSplash());
    });
};

export const setGeoLocationData = (
  data: { latitude: number, longitude: number, state: string },
  dispatch: Dispatch<LocationSplashActions>,
  onLocationGet: ?(UnaryFn<RegionData, mixed> | NullaryFn<mixed>)
) => {
  TvgGeolocation.setLocation(data.latitude, data.longitude).then(
    (locationData) => {
      dispatch(getGeoLocation({ ...locationData, state: get(data, "state") }));

      if (onLocationGet && isFunction(onLocationGet)) {
        // $FlowFixMe
        onLocationGet(locationData);
        dispatch(setLocationLoading(false));
        dispatch(setOnLocationGet(null));
      }
    }
  );
};
