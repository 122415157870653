// @flow
import React, { PureComponent } from "react";
import { noop } from "lodash";
import type { BinaryFn } from "@tvg/types/Functional";
import type { MapFeatureToggles } from "@tvg/types/Product";

import Toggle from "../../_atom/Toggle";
import {
  FeatureTogglesContainer,
  FeatureToggleItem
} from "./styled-components";

type Props = {
  featureToggles: MapFeatureToggles,
  onFeatureToggleClick: BinaryFn<string, boolean, void>
};

export default class FeatureToggles extends PureComponent<Props> {
  static defaultProps = {
    featureToggles: [],
    onFeatureToggleClick: noop
  };

  render() {
    return (
      <FeatureTogglesContainer>
        <ul>
          {Object.keys(this.props.featureToggles).map((key) => (
            <FeatureToggleItem key={key}>
              <span>{key}</span>
              <Toggle
                on={this.props.featureToggles[key]}
                onClick={() =>
                  this.props.onFeatureToggleClick(
                    key,
                    this.props.featureToggles[key]
                  )
                }
                qaLabel={key}
              />
            </FeatureToggleItem>
          ))}
        </ul>
      </FeatureTogglesContainer>
    );
  }
}
