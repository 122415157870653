// @flow
import styled, { css } from "styled-components";

export const MessageContainer = styled.div`
  padding: ${(props) => !props.sideToSide && "8px"};
  ${(props) =>
    props.sideToSide &&
    css`
      > :first-child {
        border-radius: 0;
      }
    `};
`;

export const TopMessagesContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  > :first-child {
    padding-bottom: 4px;
  }

  > div + div {
    padding-bottom: 4px;
    padding-top: 4px;
  }
`;

export const BottomMessagesContainer = styled.div`
  position: fixed;
  bottom: ${(props) => (props.isFooterVisible ? "48px" : "0")};
  left: 0;
  width: 100%;
  z-index: 1000;

  > :last-child {
    padding-top: 8px;
  }

  > div:not(:last-child) {
    padding-bottom: 0;
  }
`;
