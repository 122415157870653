// @flow
import { get, attempt } from "lodash/fp";
import type { OperationComponent } from "@apollo/client";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import type { ApolloProps } from "../types";

const QUERY_VARIABLES = {
  trackCode: null,
  raceNumber: null
};

const getProps = (result) => {
  const props = {};

  // Handle current race data
  attempt(() => {
    const currentRace = result.data.races[0];
    Object.assign(props, currentRace);
  });

  return props;
};

export default {
  options: (props: ApolloProps) => {
    const variables = {
      ...QUERY_VARIABLES,
      trackCode: (
        get("currentRace.track.trackCode", props) || ""
      ).toUpperCase(),
      raceNumber: get("currentRace.raceNumber", props) || "",
      wagerProfile: get("wagerProfile", props) || getPortByBrand()
    };

    return {
      fetchPolicy: "cache-first",
      ssr: false,
      variables
    };
  },
  props: (result: OperationComponent<Response>) => ({
    currentRace: { ...getProps(result) },
    isLoading:
      result.data.networkStatus === 1 || result.data.networkStatus === 2
  })
};
