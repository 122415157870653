// @flow
import { get, attempt, isError } from "lodash";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import queryString from "query-string";

type TrackViewProps = {
  location: {
    pathname: string,
    search: string,
    hash: string
  }
};

type IOSTrackingResponseData = {
  appVersion: string,
  deviceName: string,
  osVersion: string,
  status: number
};

export const ATTStatus = {
  notDetermined: 0,
  restricted: 1,
  denied: 2,
  authorized: 3
};

export const getStatusPretty = (status: number) => {
  if (status === ATTStatus.authorized) {
    return "granted";
  }
  if (status === ATTStatus.denied) {
    return "blocked";
  }

  return "Open Modal";
};

export const sendATTracking = (trackingData: IOSTrackingResponseData) => {
  const { appVersion, deviceName, osVersion, status } = trackingData;

  mediator.base.dispatch({
    type: "IOS_TRACKING_PERMISSION",
    payload: {
      label: getStatusPretty(+status),
      device: deviceName,
      osVersion,
      appVersion
    }
  });
};

const sendTrackView = (props: TrackViewProps) => {
  const pathname = get(props, "location.pathname", "");
  const pathnameSplitted = pathname.split("/");
  attempt(() => {
    const trackName =
      typeof pathnameSplitted[3] !== "undefined" ? pathnameSplitted[3] : "";
    const urlParams = queryString.parse(get(props, "location.search"));

    const oldRaceNumber =
      typeof pathnameSplitted[4] !== "undefined" &&
      pathnameSplitted[4].includes("-")
        ? pathnameSplitted[4].split("-")[1]
        : "";

    const raceNumber =
      urlParams && urlParams.race ? urlParams.race : oldRaceNumber;

    mediator.base.dispatch({
      type: "TRACK_VIEW",
      payload: {
        trackName,
        raceNumber
      }
    });
  });
};

const sendGTMPageView = (
  props: TrackViewProps,
  updateRace: boolean = false
) => {
  const searchesToExclude = ["?promo="];
  const excludeCurrentSearch = searchesToExclude.some((search) => {
    if (props.location && props.location.search) {
      return props.location.search.includes(search);
    }
    return false;
  });
  // Prevent "promo_offer page view" to be triggered twice
  if (excludeCurrentSearch) {
    return;
  }

  let sport;

  let registrationStatus = attempt(() =>
    localStorage.getItem("userLoginOnce") ? "Registered" : "Unregistered"
  );

  if (isError(registrationStatus)) {
    registrationStatus = "Unregistered";
  }

  let privateBrowser = attempt(() =>
    localStorage.getItem("privateMode") === "true" ? "Yes" : "No"
  );

  if (isError(privateBrowser)) {
    privateBrowser = "No";
  }

  let promoCode;
  const isContentCardsInboxShown =
    props.location &&
    props.location.pathname.indexOf("/racetracks") !== 0 &&
    props.location.pathname.indexOf("/live") !== 0;

  if (isContentCardsInboxShown) {
    promoCode = attempt(() => localStorage.getItem("promoCode"));

    if (!promoCode) {
      promoCode = undefined;
    } else {
      localStorage.removeItem("promoCode");
    }
  }

  if (
    props.location &&
    props.location.pathname.indexOf("/racetracks") > -1 &&
    updateRace
  ) {
    sport = "Horse Racing";
    sendTrackView(props);
  } else if (
    props.location &&
    props.location.pathname.indexOf("greyhounds") > -1 &&
    updateRace
  ) {
    sendTrackView(props);
    sport = "Greyhounds Racing";
  }

  const state =
    get(props, "user.homeState") === ""
      ? undefined
      : get(props, "user.homeState");

  const accountId =
    get(props, "user.accountNumber") === ""
      ? undefined
      : get(props, "user.accountNumber");

  const loginStatus = get(props, "user.isLogged", false)
    ? "Logged In"
    : "Logged Out";

  requestAnimationFrame(() => {
    if (
      props.location &&
      props.location.pathname.indexOf("/registration") > -1
    ) {
      mediator.base.dispatch({
        type: "REGISTRATION:PAGE_VIEW",
        payload: {
          location: props.location,
          accountId,
          loginStatus,
          residenceState: state
        }
      });
    } else {
      let page;

      switch (props.location.pathname) {
        case "/preferences":
          page = `${get(props, "location.pathname")}#${
            get(props, "enableNotificationsTab", false)
              ? "notifications"
              : "betting"
          }`;
          break;
        default:
          page =
            get(props, "location.pathname") + get(props, "location.hash", "");
      }

      mediator.base.dispatch({
        type: "PAGE_VIEW",
        payload: {
          siteVersion: tvgConf().gaConfig().siteVersion,
          productVersion: tvgConf().gaConfig().productVersion,
          accountId,
          logged: loginStatus,
          page,
          appVersion:
            get(props, "appVersion") !== ""
              ? get(props, "appVersion")
              : undefined,
          balance: get(props, "user.balance", undefined),
          state,
          registrationStatus,
          sport,
          privateBrowser,
          graphVersion: "v2",
          ...(isContentCardsInboxShown ? { promoCode } : null),
          isReturningUser:
            get(props, "user.returningUser") || loginStatus === "Logged In"
        }
      });
    }
  });
};

export default sendGTMPageView;
