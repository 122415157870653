// @flow

import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import Icon from "../../_static/Icons";
import ButtonLink from "../../_atom/Buttons/buttonLink";

import buildColor from "../../_static/ColorPalette";
import { fontBold, fontMedium, fontNormal } from "../../_static/Typography";

import { generateTransition, cubic, linear } from "../../_static/Transitions";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderContent = styled.div`
  width: 100%;
  min-width: 320px;
  height: 56px;
  ${({ hasDescription }) =>
    hasDescription
      ? css`
          padding: 12px 12px 0px 12px;
        `
      : css`
          padding: 12px;
        `}
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: ${buildColor("blue", "700")};

  &.monochromatic {
    background-color: ${buildColor("white", "100")};
  }

  &.lowercaseMonochromatic {
    background-color: ${buildColor("white", "100")};
  }

  &.logo-login {
    background-color: ${buildColor("white", "100")};
  }

  &.logo-highlighted {
    box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
    ${({ isMobile }) =>
      isMobile
        ? css`
            background-color: ${buildColor("white", "100")};
          `
        : css`
            background-color: transparent;
          `}
  }
`;

export const LoginSignup = styled.div`
  display: flex;

  & > a:not(:last-child) {
    margin-right: 8px;
  }
`;

export const LoginSignupInner = styled.span`
  height: 12px;
  line-height: 14px;
`;

export const LockIcon = styled(Icon)`
  margin-right: 4px;
`;

export const LogoNavigationContainer = styled.div`
  align-self: stretch;
  svg {
    margin-bottom: -1.5px;
  }

  ${({ isTitleCenter }) => isTitleCenter && "width: 100%"};

  & > a {
    height: 100%;
    display: flex;
    align-items: center;
    line-height: 0;
    position: relative;
    text-decoration: none;
    z-index: 1;
    will-change: transform, opacity;
    transition: ${generateTransition(cubic, "transform")},
      ${generateTransition(linear, "opacity")};
    ${(props) =>
      props.isNavigationShown &&
      css`
        pointer-events: none;
        transform: translate3d(0, -56px, 0);
        opacity: 0;
      `};
  }

  &.logo-highlighted {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const AnimationContainer = styled.div`
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 0;
`;

export const RaceNavigationContainer = styled.div`
  will-change: transform, opacity;
  transition: ${generateTransition(cubic, "transform")},
    ${generateTransition(linear, "opacity")};
  ${(props) =>
    props.isNavigationShown
      ? css`
          opacity: 1;
          transform: translateY(0);
        `
      : css`
          opacity: 0;
          transform: translateY(50px);
        `};
`;

export const VideoButtonContainer = styled.div`
  margin-left: 8px;
  will-change: transform, opacity;
  transition: ${generateTransition(cubic, "transform")},
    ${generateTransition(linear, "opacity")};
  ${(props) =>
    props.isVideoButtonShown
      ? css`
          opacity: 1;
          transform: translateY(0);
        `
      : css`
          opacity: 0;
          transform: translateY(50px);
        `};
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  color: ${buildColor("white", "100")};
`;

export const UserImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 32px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  background-color: ${buildColor("white", "20")};
  border-radius: 50%;
  margin-right: 8px;
  overflow: hidden;
`;

export const UserImage = styled.img`
  width: 32px;
  height: auto;
  max-height: 32px;
  align-self: flex-end;
`;

export const HeaderLabelContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ center }) =>
    center === "true" &&
    css`
      justify-content: center;

      & svg {
        position: absolute;
        left: 0;
      }
    `};
`;

export const HeaderLabel = styled.span`
  font-family: ${fontBold};
  ${({ headerClass }) =>
    headerClass === "lowercaseMonochromatic"
      ? css`
          text-transform: capitalize;
          font-size: 18px;
          font-weight: bold;
        `
      : css`
          text-transform: uppercase;
          font-size: 16px;
        `}
  color: ${({ headerClass }) =>
    headerClass === "monochromatic" || headerClass === "lowercaseMonochromatic"
      ? buildColor("grey", "900")
      : buildColor("white", "100")};
  padding-left: 12px;
`;

export const ActionToolsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ContentCardsInboxContainer = styled.div`
  margin: 0 4px 0 16px;
`;

const customButtonSharedStyles = css`
  height: 32px;
  padding: 8px;
  font-size: 14px;
  text-transform: capitalize;
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.06), 0 1px 2px rgba(17, 43, 68, 0.08);
  border-radius: 2px;
  font-family: ${fontMedium};
`;

export const ButtonLinkStyled = styled(ButtonLink)`
  ${customButtonSharedStyles};
`;

export const ButtonTransparent = styled(ButtonLink)`
  ${customButtonSharedStyles};
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.isSelected && !props.isDisabled
      ? buildColor("white", "20")
      : buildColor("white", "10")};
  color: ${({ isGrey }) =>
    isGrey ? buildColor("grey", "900") : buildColor("white", "100")};

  /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
  @media (pointer: fine) {
    &:hover {
      background-color: ${(props) =>
        !props.isDisabled && buildColor("white", "20")};
    }
  }

  &:active {
    background-color: ${(props) =>
      !props.isDisabled && buildColor("white", "20")};

    /* on touch devices (coarse), the active color should be the hover spec according to the style guide on zeplin */
    @media (pointer: coarse) {
      background-color: ${(props) =>
        !props.isDisabled && buildColor("white", "20")};
    }
  }
`;

export const ChildContainer = styled.div`
  margin-left: auto;
`;

export const HeaderDescriptionContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 50px 12px 50px;
  background-color: ${buildColor("white", "100")};
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
`;

export const HeaderDescription = styled.p`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
`;
