// @flow
import React from "react";
import SegmentedControl from "@tvg/atomic-ui/_molecule/SegmentedControl";
import { useColorTokens } from "@tvg/design-system";
import TabGroup from "@tvg/ipp/src/components/TabGroup";
import { Controllers, Filters, Header } from "./styled-components";
import type { Props } from "./types";

const MyBetsHeaderTemplate = ({
  tabs,
  timeFrameTabs,
  shouldDisplayTimeFrameTabs
}: Props) => {
  const colorToken = useColorTokens("groupedfiltercell.border.hover");
  return (
    <Header data-qa-label="myBets-headerTabs">
      <Controllers data-qa-label="myBets-controllers">
        <SegmentedControl tabs={tabs} withAnimation />
      </Controllers>
      {shouldDisplayTimeFrameTabs && (
        <Filters data-qa-label="myBets-filters">
          <TabGroup
            tabs={timeFrameTabs}
            stretch
            type="PILL"
            borderColorToken={colorToken}
          />
        </Filters>
      )}
    </Header>
  );
};

export default MyBetsHeaderTemplate;
