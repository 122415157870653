// @flow
import type { UpdateBalanceActionType } from "@tvg/shared-actions/UserActions";
import type { WroWager, WroWagerGroup } from "@tvg/api/wro/types";
import type { ActiveTabEnum, SettledTabEnum } from "./types";

type ActiveBetsInfoType = {
  bets: WroWagerGroup[],
  totalBets: number,
  totalAmount: number
};

type ModalOpen = {
  type: "MYBETS_OPEN"
};

type ModalClose = {
  type: "MYBETS_CLOSE"
};

type SuccessBetCancelAction = {
  type: "MYBETS_CANCEL_SUCCESS",
  payload: {
    wager: WroWager
  }
};

type GetActiveBetsAction = {
  type: "MYBETS_GET_ACTIVE_BETS",
  payload: ActiveBetsInfoType
};

type SetSelectedTab = {
  type: "MYBETS_SET_SELECTED_TAB",
  payload: ActiveTabEnum
};

type SetSelectedSettledTab = {
  type: "MYBETS_SET_SELECTED_SETTLED_TAB",
  payload: SettledTabEnum
};

type SetUpcomingBetsBadge = {
  type: "MYBETS_SET_UPCOMING_BETS_BADGE",
  payload: number
};

type UpdateDeletedBets = {
  type: "MYBETS_UPDATE_DELETED_BETS",
  payload: {
    serialNumber: string,
    shouldRemove: boolean
  }
};

export type Actions =
  | GetActiveBetsAction
  | SuccessBetCancelAction
  | ModalOpen
  | ModalClose
  | UpdateBalanceActionType
  | SetSelectedTab
  | SetSelectedSettledTab
  | SetUpcomingBetsBadge
  | UpdateDeletedBets;

export const successBetCancel = (wager: WroWager): SuccessBetCancelAction => ({
  type: "MYBETS_CANCEL_SUCCESS",
  payload: { wager }
});

export const openMyBets = (): ModalOpen => ({
  type: "MYBETS_OPEN"
});

export const closeMyBets = (): ModalClose => ({
  type: "MYBETS_CLOSE"
});

export const setActiveBetsInfo = (
  activeBetsInfo: ActiveBetsInfoType
): GetActiveBetsAction => ({
  type: "MYBETS_GET_ACTIVE_BETS",
  payload: activeBetsInfo
});

export const setSelectedTab = (selectedTab: ActiveTabEnum): SetSelectedTab => ({
  type: "MYBETS_SET_SELECTED_TAB",
  payload: selectedTab
});

export const setSelectedSettledTab = (
  selectedSettledTab: SettledTabEnum
): SetSelectedSettledTab => ({
  type: "MYBETS_SET_SELECTED_SETTLED_TAB",
  payload: selectedSettledTab
});

export const setUpcomingBetsBadge = (
  badgeCounter: number
): SetUpcomingBetsBadge => ({
  type: "MYBETS_SET_UPCOMING_BETS_BADGE",
  payload: badgeCounter
});

export const updateDeletedBets = (
  serialNumber: string,
  shouldRemove: boolean
): UpdateDeletedBets => ({
  type: "MYBETS_UPDATE_DELETED_BETS",
  payload: {
    serialNumber,
    shouldRemove
  }
});
