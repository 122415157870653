// @flow
import type { Dispatch } from "redux";
import { attempt, isFunction, get } from "lodash";
import { type IconTypeEnum } from "@tvg/atomic-ui/_templates/ErrorPage/types";
import type { Brand } from "@tvg/conf/src/types";
import TvgGeolocation, { type RegionData } from "@tvg/geolocation";
import openExternalLinkIosFunc from "@tvg/utils/mediatorUtils";
import mediator from "@tvg/mediator";
import type { UnaryFn, NullaryFn } from "@tvg/types/Functional";

import {
  closeLocationSplash,
  openLocationSplash,
  setLocationDenied,
  setLocationUnsupported,
  setLocationTimedOut,
  setLocationUnavailable,
  setLocationUnknownError,
  setLocationLoading,
  getGeoLocation,
  setOnLocationGet,
  type LocationSplashActions
} from "./actions";
import getInstructions from "./instructions";
import type { AndroidBlockedStateMessages } from "./types";

export const requestLocation = (
  dispatch: Dispatch<LocationSplashActions>,
  geolocation: TvgGeolocation,
  onLocationGet: ?(UnaryFn<RegionData, mixed> | NullaryFn<mixed>),
  fromTimeout: boolean = false
) => {
  if (TvgGeolocation.isGeolocationAvailable()) {
    dispatch(setLocationLoading(true));
    geolocation
      .requestLocation()
      .then((locationData) => {
        if (
          window &&
          window.localStorage &&
          isFunction(window.localStorage.setItem)
        ) {
          attempt(() =>
            window.localStorage.setItem("shownLocationSplash", true)
          );
        }
        dispatch(getGeoLocation(locationData));
        if (onLocationGet && isFunction(onLocationGet)) {
          // $FlowFixMe
          onLocationGet(locationData);
        }
        dispatch(setLocationLoading(false));
        dispatch(setOnLocationGet(null));
        dispatch(closeLocationSplash());
      })
      .catch((error) => {
        dispatch(setLocationLoading(false));
        if (
          window &&
          window.localStorage &&
          isFunction(window.localStorage.setItem)
        ) {
          attempt(() =>
            window.localStorage.setItem("shownLocationSplash", true)
          );
        }
        switch (error.code) {
          case error.PERMISSION_DENIED:
            dispatch(setLocationDenied());
            dispatch(openLocationSplash());
            break;
          case error.POSITION_UNAVAILABLE:
            if (fromTimeout) {
              requestLocation(dispatch, geolocation, onLocationGet);
            } else {
              dispatch(setLocationUnavailable());
              dispatch(openLocationSplash());
            }
            break;
          case error.TIMEOUT:
            dispatch(setLocationTimedOut());
            dispatch(openLocationSplash());
            break;
          case error.UNKNOWN_ERROR:
          default:
            dispatch(setLocationUnknownError(false));
            dispatch(openLocationSplash());
            break;
        }
      });
  } else {
    dispatch(setLocationUnsupported());
    dispatch(openLocationSplash());
  }
};

export const buildAllowLocationSplash = (
  dispatch: Dispatch<LocationSplashActions>,
  geolocation: TvgGeolocation,
  isLoading: boolean,
  onLocationGet: ?(UnaryFn<RegionData, mixed> | NullaryFn<mixed>),
  brand: Brand,
  customerServicesUrl: string,
  isIos: boolean
) => ({
  brand,
  onClose: isLoading
    ? undefined
    : () => {
        dispatch(setOnLocationGet(null));
        dispatch(closeLocationSplash());
      },
  icon: "location",
  preTitle: "Please Allow",
  title: "Location Services",
  description:
    "Sorry but for legal reasons we need location services to be enabled to confirm you are in an area that allows online horse wagering.",
  infoText:
    "If prompted to share your current location press \u201cok\u201d or \u201callow\u201d",
  showCustomerServicesUrl: true,
  customerServicesUrl,
  isIos,

  iOSMessageUsCallback: () => openExternalLinkIosFunc(customerServicesUrl),

  buttons: [
    [
      {
        type: "primary",
        icon: "location",
        text: "allow location services",

        onClick: () => requestLocation(dispatch, geolocation, onLocationGet),

        isDisabled: isLoading
      }
    ],
    [
      {
        type: "tertiary",
        text: "I don't want to bet",

        onClick: () => {
          dispatch(setOnLocationGet(null));
          dispatch(closeLocationSplash());
        },

        isDisabled: isLoading
      }
    ]
  ]
});

export const buildBlockedCountrySplash = (
  dispatch: Dispatch<LocationSplashActions>,
  brand: Brand,
  isLoading: boolean
) => ({
  brand,
  isLoading,
  title: `${brand.toUpperCase()} is not available outside the US`,
  description: `Wagering on ${brand} is not enabled while abroad. </br> When you return home, come back and try again.`,
  isAndroidBlocked: true,
  androidBlockType: "country",
  buttons: [
    [
      {
        type: "primary",
        icon: "location",
        text: "Check my location again",

        onClick: () => {
          dispatch(setLocationLoading(true));
          mediator.ios.dispatch({ type: "ASK_LOCATION" });
        }
      }
    ]
  ]
});

export const buildAndroidBlockedState = (
  messages: AndroidBlockedStateMessages,
  dispatch: Dispatch<LocationSplashActions>,
  brand: Brand,
  isLoading: boolean
) => {
  return {
    brand,
    isLoading,
    title: get(messages, `tvg.title`),
    description: get(messages, `tvg.description`),
    isAndroidBlocked: true,
    androidBlockType: "state"
  };
};

export const buildLocationDeniedSplash = (
  dispatch: Dispatch<LocationSplashActions>,
  brand: Brand,
  customerServicesUrl: string,
  isApp: boolean
) => ({
  brand,

  onClose: () => {
    dispatch(setOnLocationGet(null));
    dispatch(closeLocationSplash());
  },

  icon: "warning",
  preTitle: "Please Allow",
  title: "Location Services",
  infoText: "We are unable to access your location",
  infoType: "error",
  instructions: getInstructions("denied"),
  showCustomerServicesUrl: true,
  customerServicesUrl,
  isApp,

  iOSMessageUsCallback: () => openExternalLinkIosFunc(customerServicesUrl),

  buttons: [
    [
      {
        type: "primary",
        icon: "refresh",
        text: "refresh",

        onClick: () => {
          if (location && isFunction(location.reload)) {
            location.reload();
          }
        }
      }
    ]
  ]
});

export const buildLocationUnsupportedSplash = (
  dispatch: Dispatch<LocationSplashActions>,
  brand: Brand,
  customerServicesUrl: string,
  isApp: boolean
) => ({
  brand,

  onClose: () => {
    dispatch(setOnLocationGet(null));
    dispatch(closeLocationSplash());
  },

  icon: "warning",
  title: "Browser Unsupported",
  description:
    "Sorry but we don't support the browser you're using, please use the latest version of Chrome for the best results.",
  infoText:
    "This site requires location services which are not supported in the browser you are using",
  showCustomerServicesUrl: true,
  customerServicesUrl,
  isApp,

  iOSMessageUsCallback: () => openExternalLinkIosFunc(customerServicesUrl),

  buttons: [
    [
      {
        type: "primary",
        text: "close",

        onClick: () => {
          dispatch(setOnLocationGet(null));
          dispatch(closeLocationSplash());
        }
      }
    ]
  ]
});

export const buildUnknownErrorSplash = (
  dispatch: Dispatch<LocationSplashActions>,
  geolocation: TvgGeolocation,
  isLoading: boolean,
  onLocationGet: ?(UnaryFn<RegionData, mixed> | NullaryFn<mixed>),
  brand: Brand,
  customerServicesUrl: string,
  isApp: boolean,
  showClose: boolean = false
) => ({
  brand,
  onClose: isLoading
    ? undefined
    : () => {
        dispatch(setOnLocationGet(null));
        dispatch(closeLocationSplash());
      },
  icon: "warning",
  title: "Unknown Error",
  description:
    "Sorry we're not sure what went wrong, please check you are connected to the internet and location services are turned on and try again.",
  infoText: "An error has occurred whilst trying to access location services",
  showCustomerServicesUrl: true,
  customerServicesUrl,
  isApp,

  iOSMessageUsCallback: () => openExternalLinkIosFunc(customerServicesUrl),

  buttons: [
    [
      {
        type: "primary",
        text: showClose ? "close" : "try again",

        onClick: () => {
          if (showClose) {
            dispatch(setOnLocationGet(null));
            dispatch(closeLocationSplash());
          } else {
            requestLocation(dispatch, geolocation, onLocationGet);
          }
        },

        isDisabled: isLoading
      }
    ]
  ]
});

export const buildPositionUnavailableSplash = (
  dispatch: Dispatch<LocationSplashActions>,
  brand: Brand,
  customerServicesUrl: string,
  isApp: boolean
) => ({
  brand,

  onClose: () => {
    dispatch(setOnLocationGet(null));
    dispatch(closeLocationSplash());
  },

  icon: "warning",
  preTitle: "Please Allow",
  title: "Location Services",
  infoText: "We are unable to access your location",
  infoType: "error",
  instructions: getInstructions("unavailable"),
  showCustomerServicesUrl: true,
  customerServicesUrl,
  isApp,

  iOSMessageUsCallback: () => openExternalLinkIosFunc(customerServicesUrl),

  buttons: [
    [
      {
        type: "primary",
        icon: "refresh",
        text: "refresh",

        onClick: () => {
          if (location && isFunction(location.reload)) {
            location.reload();
          }
        }
      }
    ]
  ]
});

export const buildTimedOutSplash = (
  dispatch: Dispatch<LocationSplashActions>,
  geolocation: TvgGeolocation,
  isLoading: boolean,
  onLocationGet: ?(UnaryFn<RegionData, mixed> | NullaryFn<mixed>),
  brand: Brand,
  customerServicesUrl: string,
  isApp: boolean
) => ({
  brand,
  onClose: isLoading
    ? undefined
    : () => {
        dispatch(setOnLocationGet(null));
        dispatch(closeLocationSplash());
      },
  icon: "warning",
  title: "Request Timed Out",
  description:
    "Sorry we lost connection, please try again, this could be your internet is unstable or our site is very busy.",
  infoText:
    "The request to check if your location services are enabled has timed out",
  showCustomerServicesUrl: true,
  customerServicesUrl,
  isApp,

  iOSMessageUsCallback: () => openExternalLinkIosFunc(customerServicesUrl),

  buttons: [
    [
      {
        type: "primary",
        text: "try again",

        onClick: () => {
          requestLocation(dispatch, geolocation, onLocationGet, true);
        },

        isDisabled: isLoading
      }
    ]
  ]
});

export const buildUnauthorizedLocationSplash = (
  dispatch: Dispatch<LocationSplashActions>,
  geolocation: TvgGeolocation,
  isLoading: boolean,
  brand: Brand,
  customerServicesUrl: string,
  isApp: boolean
) => ({
  brand,
  onClose: isLoading
    ? undefined
    : () => {
        dispatch(setOnLocationGet(null));
        dispatch(closeLocationSplash());
      },
  icon: "warning",
  title: "Invalid wagering state",
  description:
    "It seems you are trying to access the application from a non valid wagering state.",
  infoType: "warning",
  infoText:
    "Sorry but for legal reasons you need to be in valid wagering state, if you are please check your location services are enabled or you can <a target='_blank' href='/redirectengine?type=messageus' style='color:inherit; text-decoration: underline; font-weight:bold; font-family: inherit; display: contents;'>message our customer service</a> for more info.",
  showCustomerServicesUrl: true,
  customerServicesUrl,
  isApp,

  iOSMessageUsCallback: () => openExternalLinkIosFunc(customerServicesUrl),

  buttons: [
    [
      {
        type: "primary",
        text: "Close",

        onClick: () => {
          dispatch(setOnLocationGet(null));
          dispatch(closeLocationSplash());
        }
      }
    ]
  ]
});

export const buildTimeoutExclusionSplash = (
  brand: Brand,
  customerServicesUrl: string,
  isApp: boolean,
  title: string,
  description: string,
  icon: IconTypeEnum
) => ({
  brand,
  icon,
  title,
  description,
  infoType: "warning",
  showCustomerServicesUrl: true,
  customerServicesUrl,
  isApp,
  iOSMessageUsCallback: () => openExternalLinkIosFunc(customerServicesUrl),
  buttons: [],
  className: "rgErrorPageMainContent"
});
