// @flow
import UAParser from "ua-parser-js";

const androidInstructions = [
  {
    title: "LOCATION SERVICES ARE DISABLED",
    instructions: [
      "Please turn it on from your mobile settings and refresh the app"
    ]
  },
  {
    title: "IF YOU PREVIOUSLY DENIED TVG ACCESS TO LOCATION SERVICES",
    instructions: [
      "Please clear and reset your site settings from your web browser"
    ]
  }
];

const iosInstructions = [
  {
    title: "LOCATION SERVICES ARE DISABLED",
    instructions: [
      "Please turn it on from your mobile settings and refresh the app"
    ]
  },
  {
    title: "IF YOU PREVIOUSLY DENIED TVG ACCESS TO LOCATION SERVICES",
    instructions: [
      "Please reset your location and privacy settings from your mobile settings"
    ]
  }
];

const getInstructions = (reason: string) => {
  const ua = new UAParser();
  switch (reason) {
    case "unavailable":
    case "denied":
    default:
      if (ua.getOS().name === "iOS") {
        return iosInstructions;
      }
      return androidInstructions;
  }
};

export default getInstructions;
