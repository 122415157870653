// @flow
import axios from "axios";
import type { Dispatch } from "redux";
import TVGConf from "@tvg/conf";
import { updateBalance } from "@tvg/shared-actions/UserActions";
import {
  failBalanceRequest,
  successBalancePollerRequest
} from "../actions/user";
import type { UserActions } from "../actions/user";

// TODO: this should be on the tvg-api
export const tvgHeaders = () => ({
  "content-type": "application/json",
  "x-clientapp": "tvg4",
  "x-tvg-context": TVGConf().context(),
  "x-requested-with": "XMLHttpRequest"
});

function userBalance(accountId: number, isPoller: boolean = false) {
  const requestOptions = {
    method: "get",
    url: `${TVGConf().config().service.uwt}/users/${accountId}/balance${
      isPoller ? "?isPoller=true" : ""
    }`,
    headers: tvgHeaders(),
    withCredentials: true
  };

  return axios(requestOptions);
}

const getBalance = (
  dispatch: Dispatch<UserActions>,
  accountId: number,
  isPoller: boolean = false
) =>
  userBalance(accountId, isPoller)
    .then((response) => dispatch(updateBalance(response.data.balance)))
    .catch((err: Error) => dispatch(failBalanceRequest(err)));

export default {
  getBalance
};

export function userBalancePollerTime(dispatch: Dispatch<UserActions>) {
  const url = `${TVGConf().config().service.capi}/configs/balance-polling-time`;
  const requestOptions = {
    method: "get",
    url,
    headers: tvgHeaders(),
    withCredentials: true
  };
  return axios(requestOptions).then((response) =>
    dispatch(successBalancePollerRequest(+response.data.value))
  );
}
