// @flow

type ChangeFeatureToggleValueAction = {
  type: "FEATURES_CHANGE",
  payload: {
    toggleName: string,
    newValue: boolean
  }
};

export type Actions = ChangeFeatureToggleValueAction;

export const changeFeatureToggleValue = (
  toggleName: string,
  newValue: boolean
): ChangeFeatureToggleValueAction => ({
  type: "FEATURES_CHANGE",
  payload: { toggleName, newValue }
});

export default {};
