// @flow
import styled, { css } from "styled-components";
import { buildText } from "../../../_atom/Text";
import { fontMedium } from "../../../_static/Typography";

const Text = styled(
  buildText({
    fontSize: 14,
    uppercase: false
  })
)`
  text-transform: capitalize;
  font-family: ${fontMedium};
  ${(props) =>
    props.vertical
      ? css`
          display: block;
        `
      : css`
          margin-left: 4px;
        `};
`;

export default Text;
