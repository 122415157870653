// @flow
import React, { type Node } from "react";
import MobileHeader from "@tvg/mobile-header";
import type { NullaryFn } from "@tvg/types/Functional";
import type { RaceVideoFeedback } from "@tvg/types/Race";
import { Containers } from "@tvg/atomic-ui/_static/BaseComponents";
import ApolloContext from "@tvg/utils/apolloContext";
import { buildSignUpUrl } from "../utils/config";

type Props = {
  title: string,
  logoMod: string,
  openRaceNavigation: NullaryFn<mixed>,
  currentPath: string,
  wagerProfile: string,
  trackCode: string,
  raceNumber: string,
  isLogged: boolean,
  isBalanceShown: boolean,
  balance: number,
  isVisible: boolean,
  isNavigationShown: boolean,
  isVideoButtonShown: boolean,
  isActionToolsShown: boolean,
  isLogActionsShown: boolean,
  isTitleCenter?: boolean,
  videoFeedback: RaceVideoFeedback,
  onVideoButton: NullaryFn<void>,
  device: string,
  prevPath: string,
  headerClass: string,
  isContentCardsInboxShown: boolean,
  children: Node,
  currentRace: any,
  description: string,
  enablePawsQuickDeposits: boolean
};

const Header = ({
  title,
  logoMod,
  openRaceNavigation,
  wagerProfile,
  trackCode,
  raceNumber,
  isLogged,
  isBalanceShown,
  balance,
  isVisible,
  isNavigationShown,
  isVideoButtonShown,
  isActionToolsShown,
  isLogActionsShown,
  isTitleCenter = false,
  videoFeedback,
  onVideoButton,
  device,
  currentPath,
  prevPath,
  headerClass,
  isContentCardsInboxShown,
  children,
  currentRace,
  description,
  enablePawsQuickDeposits
}: Props) => (
  <ApolloContext.Consumer>
    {(value) => (
      <Containers.Header
        device={device}
        isVisible={isVisible}
        className={headerClass}
        hasDescription={!!description}
      >
        <MobileHeader
          title={title}
          logoMod={logoMod}
          signUpUrl={buildSignUpUrl()}
          prevPath={prevPath}
          currentPath={currentPath}
          loginUrl={`${currentPath}#login`}
          quickDepositUrl={
            !enablePawsQuickDeposits
              ? `${currentPath}#deposit`
              : `${currentPath}#quick-deposit`
          }
          trackCode={trackCode}
          raceNumber={raceNumber}
          isLogged={isLogged}
          wagerProfile={wagerProfile}
          isBalanceShown={isBalanceShown}
          balance={balance}
          isNavigationShown={isNavigationShown}
          openRaceNavigation={openRaceNavigation}
          isVideoButtonShown={isVideoButtonShown}
          isActionToolsShown={isActionToolsShown}
          isLogActionsShown={isLogActionsShown}
          isTitleCenter={isTitleCenter}
          layout={device === "mobile" ? "normal" : "taller"}
          videoFeedback={videoFeedback}
          onVideoButton={onVideoButton}
          device={device}
          headerClass={headerClass}
          isContentCardsInboxShown={isContentCardsInboxShown}
          currentRace={currentRace}
          behgClient={value.behgClient}
          description={description}
        >
          {children}
        </MobileHeader>
      </Containers.Header>
    )}
  </ApolloContext.Consumer>
);

export default Header;
