// @flow
import React from "react";
import { find } from "lodash";
import MobileFooter from "@tvg/mobile-footer";
import mediator from "@tvg/mediator";
import ApolloContext from "@tvg/utils/apolloContext";
import { Containers } from "@tvg/atomic-ui/_static/BaseComponents";
import type { Device } from "@tvg/conf/src/types";
import { events as AlchemerEvents } from "@urp/alchemer";

import Routes from "../Routes";
import { Hashes } from "../Hashes";

const getFooterRoute = (key: string) => {
  const { path, name } = find(Routes, (r) => r.key === key) || {
    path: "",
    name: ""
  };
  return { href: path, name };
};

const getFooterHash = (key: string) => {
  const { hash, name } = find(Hashes, (r) => r.key === key) || {
    hash: "",
    name: ""
  };
  return { href: hash, name, key };
};

export const footerItems = [
  {
    ...getFooterRoute("home"),
    onClick: () => {
      mediator.base.dispatch({
        type: "FOOTER_CLICK_HOME",
        payload: { destinationUrl: getFooterRoute("home").href }
      });
      AlchemerEvents.navigateHome();
    }
  },
  {
    ...getFooterRoute("tracks"),
    onClick: () =>
      mediator.base.dispatch({
        type: "FOOTER_CLICK_TRACKS",
        payload: { destinationUrl: getFooterRoute("tracks").href }
      })
  },
  {
    ...getFooterHash("bets"),
    onClick: () => {
      mediator.base.dispatch({ type: "MYBETS_OPEN_FROM_FOOTER" });
      mediator.base.dispatch({
        type: "FOOTER_CLICK_BETS",
        payload: { destinationUrl: getFooterHash("bets").href }
      });
    }
  },
  {
    ...getFooterRoute("picks"),
    onClick: () =>
      mediator.base.dispatch({
        type: "FOOTER_CLICK_PICKS",
        payload: { destinationUrl: getFooterRoute("picks").href }
      })
  },
  {
    ...getFooterRoute("more"),
    onClick: () =>
      mediator.base.dispatch({
        type: "FOOTER_CLICK_MORE",
        payload: { destinationUrl: getFooterRoute("more").href }
      })
  }
];

const Footer = ({
  activePath,
  isLogged,
  isVisible,
  device
}: {
  activePath: string,
  isLogged: boolean,
  isVisible: boolean,
  device: Device
}) => {
  const footerRoutes = footerItems.map((item) => {
    const href =
      item.href.indexOf("#") === 0 ? `${activePath}${item.href}` : item.href;
    return { ...item, href };
  });
  return (
    <ApolloContext.Consumer>
      {(value) => (
        <Containers.Footer device={device} isVisible={isVisible}>
          <MobileFooter
            key="footer"
            isLogged={isLogged}
            links={footerRoutes}
            behgClient={value.behgClient}
            activePath={activePath}
            isVisible={isVisible}
            layout={device !== "desktop" ? "horizontal" : "vertical"}
          />
        </Containers.Footer>
      )}
    </ApolloContext.Consumer>
  );
};

export default Footer;
