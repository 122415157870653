// @flow
import React, { PureComponent } from "react";
import { noop, get } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import type { RaceVideoFeedback } from "@tvg/types/Race";

import VideoButtonContainer from "./styled-components";
import buildButtonInfo, { videoMessages } from "./VideoButtonInfo";

export type Props = {
  /**
   * state of the button in a given button, defined by the feedback message to the user
   */
  feedback: RaceVideoFeedback,
  /**
   * size of the button
   */
  small: boolean,
  /**
   * on click callback
   */
  onClick: NullaryFn<mixed>
};

export default class VideoButton extends PureComponent<Props> {
  static defaultProps = {
    small: false,
    onClick: noop
  };

  render() {
    const { feedback, small, onClick } = this.props;

    return (
      <VideoButtonContainer
        isBold={false}
        isDisabled={
          feedback.indexOf("AVAILABLE") !== 0 &&
          feedback.indexOf("NO_REPLAY") > -1
        }
        type={
          feedback.indexOf("NO_REPLAY") === -1 ? "secondary_alt" : "primary"
        }
        onClick={onClick}
        isStretched={!small}
        qaLabel={`raceCardHeader-${get(
          videoMessages,
          `[${feedback}]`,
          ""
        ).replace(" ", "")}`}
      >
        {buildButtonInfo({ feedback, small })}
      </VideoButtonContainer>
    );
  }
}
