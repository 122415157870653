// @flow
import type { GeoActions } from "../../actions/geo";

export type State = {
  location: {
    latitude: ?number,
    longitude: ?number
  },
  regions: {
    description: string,
    id: number,
    name: string,
    type: string
  }[],
  state: string
};

export const initialState = {
  location: {
    latitude: null,
    longitude: null
  },
  regions: [
    {
      description: "",
      id: 0,
      name: "Default",
      type: "Default"
    }
  ],
  state: ""
};

const geoLocationReducer = (
  state: State = initialState,
  action: GeoActions
) => {
  switch (action.type) {
    case "GEO_LOCATION_GET": {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export default geoLocationReducer;
