// @flow

import React, { Fragment } from "react";
import type { RaceVideoFeedback } from "@tvg/types/Race";

import buildColor from "../../../_static/ColorPalette";
import Icon from "../../../_static/Icons";
import {
  video,
  noVideo,
  videoReplay,
  noVideoReplay
} from "../../../_static/Icons/icons";
import Text from "./styled-components";

const iconType = {
  NOT_AVAILABLE_REPLAY: videoReplay,
  STARTING_SOON_REPLAY: videoReplay,
  FINISHED_REPLAY: videoReplay,
  AVAILABLE_REPLAY: videoReplay,
  NOT_AVAILABLE_NO_REPLAY: noVideo,
  STARTING_SOON_NO_REPLAY: video,
  FINISHED_NO_REPLAY: noVideoReplay,
  AVAILABLE_NO_REPLAY: video
};

export const videoMessages = {
  NOT_AVAILABLE_REPLAY: "Video Replay",
  STARTING_SOON_REPLAY: "Video Replay",
  FINISHED_REPLAY: "Video Replay",
  AVAILABLE_REPLAY: "Video Replay",
  NOT_AVAILABLE_NO_REPLAY: "Not Available",
  STARTING_SOON_NO_REPLAY: "Starting Soon",
  FINISHED_NO_REPLAY: "No Replay",
  AVAILABLE_NO_REPLAY: "Watch Live"
};

type OptionsData = {
  feedback: RaceVideoFeedback,
  small?: boolean,
  vertical?: boolean,
  iconSize?: number
};

export default (optionsData: OptionsData) => {
  const options = {
    small: false,
    vertical: false,
    iconSize: 16,
    ...optionsData
  };

  return (
    <Fragment>
      <Icon
        color={buildColor("white", "100")}
        icon={iconType[options.feedback]}
        size={options.iconSize}
      />
      {!options.small && (
        <Text vertical={options.vertical}>
          {videoMessages[options.feedback]}
        </Text>
      )}
    </Fragment>
  );
};
