// @flow

import React, { Component } from "react";
import { bindAll, noop, get } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import type { IconType } from "../../_static/Icons/Types.js.flow";
import { LinkFooter, Text } from "./styled-components";

export type Props = {
  /**
   * Classname to style the component
   */
  className?: string,
  /**
   * label to show under the icon
   */
  label: string,
  /**
   * path to go to
   */
  href: string,
  /**
   * Number to show some info (e.g. number of active bets)
   */
  counter: number,
  /**
   * Link is selected
   */
  selected?: boolean,
  /**
   * Icon to show above the label.
   */
  icon?: IconType,
  /**
   * On click call back (ie. gtm usage)
   */
  onClick?: NullaryFn<mixed>,
  /**
   * Put text next to the icon
   */
  isTextAside?: boolean
};

export class FooterLink extends Component<Props> {
  static defaultProps = {
    className: "",
    label: "",
    href: "/",
    counter: 0,
    selected: false,
    icon: {},
    onClick: undefined,
    isTextAside: false
  };

  animClass: string;

  animTimeout: TimeoutID;

  constructor(props: Props) {
    super(props);
    this.animClass = "";
    bindAll(this, ["applyAnimation"]);
  }

  componentDidMount() {
    if (this.props.counter > 0) {
      this.applyAnimation("run-animation");
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.counter !== this.props.counter) {
      this.applyAnimation("run-animation");
    }
  }

  findFooterLinkColor = (): string => {
    if (get(this.props, "selected", false)) {
      return buildColor("white", "100");
    }
    return buildColor("blue", "300");
  };

  applyAnimation(animName: string): void {
    this.animClass = `${animName}`;

    if (this.animTimeout) {
      clearTimeout(this.animTimeout);
    }

    this.animTimeout = setTimeout(() => {
      this.animClass = "";
      this.forceUpdate();
    }, 250);

    this.forceUpdate();
  }

  render() {
    const componentProps = {
      counter: this.props.counter,
      selected: this.props.selected,
      to: this.props.href,
      label: this.props.label,
      onClick: this.props.onClick,
      className: `${this.props.className || ""} ${this.animClass}`,
      istextaside: this.props.isTextAside ? 1 : 0,
      fontSize: this.props.isTextAside ? 14 : 10
    };
    const footerLinkColor = this.findFooterLinkColor();

    return (
      <LinkFooter {...componentProps} onTouchStart={noop}>
        {this.props.icon && (
          <Icon icon={this.props.icon} size={20} color={footerLinkColor} />
        )}
        <Text color={footerLinkColor}>{this.props.label}</Text>
      </LinkFooter>
    );
  }
}

// $FlowFixMe
export default FooterLink;
