// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindAll } from "lodash";

import FeatureTogglesTemplate from "@tvg/atomic-ui/_templates/FeatureToggles";
import type { MapFeatureToggles } from "@tvg/types/Product";

import { type Actions, changeFeatureToggleValue } from "./actions";

type Props = {
  featureToggles: MapFeatureToggles,
  dispatch: Dispatch<Actions>
};

export class FeatureToggles extends Component<Props> {
  static defaultProps = {
    featureToggles: []
  };

  constructor(props: Props) {
    super(props);
    bindAll(this, "onFeatureToggleClick");
  }

  onFeatureToggleClick(toggleName: string, currentToggleValue: boolean): void {
    this.props.dispatch(
      changeFeatureToggleValue(toggleName, !currentToggleValue)
    );
  }

  render() {
    return (
      <FeatureTogglesTemplate
        featureToggles={this.props.featureToggles}
        onFeatureToggleClick={this.onFeatureToggleClick}
      />
    );
  }
}

export default connect(
  (store) => ({
    featureToggles: store.capi.featureToggles
  }),
  (dispatch: Dispatch<Actions>) => ({ dispatch })
)(FeatureToggles);
