// @flow

import React from "react";
import { noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { fileDownload } from "../../_static/Icons/icons";

import { Button, Label } from "./styled-components";

type Props = {
  handleClick: NullaryFn<void>
};
export default function DownloadButton({ handleClick }: Props) {
  return (
    <Button onClick={handleClick}>
      <Icon icon={fileDownload} color={buildColor("blue_accent", "000")} />
      <Label>Download</Label>
    </Button>
  );
}

DownloadButton.defaultProps = {
  handleClick: noop
};
